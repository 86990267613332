class RoadSign {

  constructor() {
    this.baseURL = process.env.REACT_APP_API_BASE_URL;
  }


  createRoadSign = async (token, name, campaign_id, image) => {

    // const token = localStorage.getItem('firebase_token');

    const formData = new FormData();
    formData.append('file', image, image.name);

    const roadSignData = {
      "name": name,
      "campaign_id": campaign_id
    };

    const jsonData = JSON.stringify(roadSignData);

    const blob = new Blob([jsonData], {
      type: 'application/json'
    });

    formData.append('road_sign', blob);

    if (token) {
      // Create request
      const request = new Request(`${this.baseURL}/road-sign/`, {
        method: 'POST',
        headers: {
          'accept': 'application/json',
          // 'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'X-client_type': 'web'
        },
        body: formData,
        // body: JSON.stringify(formData),
        credentials: 'include',
      });

      // Fetch request
      const response = await fetch(request);
      // console.log("response: ", response);
      const data = await response.json();
      // console.log("data: ", data);

      // 500 error handling
      if (response.status === 500) {
        throw new Error('Internal server error');
      }

      // 400 error handling
      if (response.status >= 400 && response.status < 500) {
        // console.log("data.detail: ", data.detail); 
        if (data.detail) {
          throw new Error(data.detail);
        }

        // console.log("typeof data: ", typeof data);
        if (typeof data === 'string') {
          throw new Error(data);
        }

        if (typeof data === 'object') {
          if (data['error'] === 'NAME_EXISTS') {
            throw new Error('This name is already in use. Please choose a different name.');
          }
          // throw new Error(data);
        }

        throw new Error('An error occurred');
      }

      return data;
    } else {
      console.error("No token found");
    }
  };


  createRoadSignPlacement = async (token, roadSignId, coordinates, image, user_id, campaign_id) => {

    // const token = localStorage.getItem('firebase_token');

    const formData = new FormData();
    formData.append('file', image, image.name);

    const roadSignPlacementData = {
      "road_sign_id": roadSignId,
      "coordinates": coordinates,
      "user_id": user_id,
      "campaign_id": campaign_id
    };

    const jsonData = JSON.stringify(roadSignPlacementData);

    const blob = new Blob([jsonData], {
      type: 'application/json'
    });

    formData.append('road_sign_placement', blob);

    console.log("formData: ", formData);
    for (let [key, value] of formData.entries()) {
      console.log(key, value);
    }

    if (token) {
      // Create request
      const request = new Request(`${this.baseURL}/road-sign/placement/`, {
        method: 'POST',
        headers: {
          'accept': 'application/json',
          // 'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'X-client_type': 'web'
        },
        body: formData,
        // body: JSON.stringify(formData),
        credentials: 'include',
      });

      // Fetch request
      const response = await fetch(request);
      // console.log("response: ", response);
      const data = await response.json();
      // console.log("data: ", data);

      // 500 error handling
      if (response.status === 500) {
        throw new Error('Internal server error');
      }

      // 400 error handling
      if (response.status >= 400 && response.status < 500) {
        // console.log("data.detail: ", data.detail); 
        if (data.detail) {
          throw new Error(data.detail);
        }

        // console.log("typeof data: ", typeof data);
        if (typeof data === 'string') {
          throw new Error(data);
        }

        if (typeof data === 'object') {
          if (data['error'] === 'NAME_EXISTS') {
            throw new Error('This name is already in use. Please choose a different name.');
          }
          // throw new Error(data);
        }

        throw new Error('An error occurred');
      }

      return data;
    } else {
      console.error("No token found");
    }
  };


  getRoadSign = async (token, id, campaign_id) => {
    //const token = localStorage.getItem('token');
    // const token = localStorage.getItem('firebase_token');

    if (token) {
      if (id && campaign_id) {
        // Create request
        const request = new Request(`${this.baseURL}/road-sign/?id=` + id + '&campaign_id=' + campaign_id, {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Authorization': `Bearer ${token}`,
            'X-client_type': 'web'
          }
        });
        // Fetch request
        const response = await fetch(request);
        const data = await response.json();
        //console.log(data);
        return data;
      }
      else {
        throw new Error('ID was not provided');
      }
    } else {
      console.error("No token found");
    }
  };

  getMinimalRoadSign = async (token, id, campaign_id) => {
    //const token = localStorage.getItem('token');
    // const token = localStorage.getItem('firebase_token');

    if (token) {
      if (id && campaign_id) {
        // Create request
        const request = new Request(`${this.baseURL}/road-sign/minimal/?id=` + id + '&campaign_id=' + campaign_id, {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Authorization': `Bearer ${token}`,
            'X-client_type': 'web'
          }
          //headers: {'Authorization': `Bearer ${token}`}
        });
        // Fetch request
        const response = await fetch(request);
        const data = await response.json();
        //console.log(data);
        return data;
      }
      else {
        throw new Error('ID was not provided');
      }
    } else {
      console.error("No token found");
    }
  };

  getAllRoadSigns = async (token, campaign_id) => {
    //const token = localStorage.getItem('token');
    // const localUser = JSON.parse(localStorage.getItem('user')) ?? {};
    // const token = localUser['oauth_account']['access_token'];
    // const token = localStorage.getItem('firebase_token');

    if (token) {
      if (campaign_id) {
        // Create request
        const request = new Request(`${this.baseURL}/road-sign/all/?campaign_id=` + campaign_id, {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Authorization': `Bearer ${token}`,
            'X-client_type': 'web'
          }
          //headers: {'Authorization': `Bearer ${token}`}
        });
        // Fetch request
        const response = await fetch(request);
        const data = await response.json();
        // localStorage.setItem('roadSigns', JSON.stringify(data));
        // try {
        //   localStorage.setItem('RoadSigns', JSON.stringify(data));
        // } catch (error) {
        //   console.error("Error setting RoadSigns in localStorage:", error);
        // }
        return data;
      }
      else {
        throw new Error('ID or Name was not provided');
      }
    } else {
      console.error("No token found");
    }
  };


  getAllUserRoadSigns = async (token, user_id, campaign_id) => {
    // const token = localStorage.getItem('firebase_token');

    if (token) {
      if (user_id && campaign_id) {
        // Create request
        const request = new Request(`${this.baseURL}/road-sign/all-user/?user_id=`+user_id+'&campaign_id=' + campaign_id, {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Authorization': `Bearer ${token}`,
            'X-client_type': 'web'
          }
        });
        // Fetch request
        const response = await fetch(request);
        const data = await response.json();
        // localStorage.setItem('roadSigns', JSON.stringify(data));
        return data;
      }
      else {
        throw new Error('user ID or campaign ID was not provided');
      }
    } else {
      console.error("No token found");
    }
  };


  updateRoadSignVoter = async (token, id, voter_id, old_status, touch_result, voters_pending, campaign_id, user_id) => {
    // const localUser = JSON.parse(localStorage.getItem('user')) ?? {};
    // const token = localUser['oauth_account']['access_token'];
    // const token = localStorage.getItem('firebase_token');

    const formData = {
      "id": id,
      "voter_id": voter_id,
      "old_status": old_status,
      "touch_result": touch_result,
      "voters_pending": voters_pending,
      "campaign_id": campaign_id,
      "user_id": user_id,
    };

    if (token) {
      if (id) {
        // Create request
        const request = new Request(`${this.baseURL}/road-sign/`, {
          method: 'PUT',
          headers: {
            'accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'X-client_type': 'web'
          },
          body: JSON.stringify(formData),
          credentials: 'include',
        });
        // Fetch request
        const response = await fetch(request);
        const data = await response.json();
        //console.log(data);
        return data;
      }
      else {
        throw new Error('ID or Name was not provided');
      }
    } else {
      console.error("No token found");
    }
  };

  // deleteRoadSign = async (name, farmID) => {
  //   console.log(name);
  //   console.log(farmID);
  //   if (name && farmID) {
  //     console.log("Here 3");
  //     // Create request
  //     const request = new Request('http://localhost:8000/RoadSign/?name='+name+'&farm_id='+farmID, {
  //       method: 'DELETE',
  //       credentials: 'include',
  //       //headers: {'Authorization': `Bearer ${token}`}
  //     });
  //     // Fetch request
  //     const response = await fetch(request);
  //     console.log("Here 4");
  //     console.log(response.status);


  //     if (response.status === 204) {
  //       return true;
  //     } else {
  //       const data = await response.json();
  //       if (data.detail) {
  //         throw data.detail;
  //       }
  //       throw data;
  //     }
  //   } else {
  //     throw new Error('RoadSign Name and/or Farm ID was not provided');
  //   }
  // };

}

export default new RoadSign();