import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

// react-bootstrap components
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import FloatingLabel from 'react-bootstrap/FloatingLabel';

import authAPI from '../../apis/auth/auth';


function VerifyPage() {
  const history = useHistory();
  const [email, setEmail] = useState((history && (history.location.state !== undefined)) ? (('email' in history.location.state) ? history.location.state.email : '') : '');
  const [user, setUser] = useState((history && (history.location.state !== undefined)) ? (('user' in history.location.state) ? history.location.state.user : '') : '');

  const [
    // error
    ,
    setError
  ] = useState('');

  const callSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {

      const data = await authAPI.resendVerificationEmail(user);
      if ( data ) {
        setEmail(email);
        setUser(user);
      }
    } 
    catch (err) {
      if (err instanceof Error) {
        // Handle errors thrown from frontend
        setError(err.message);
      } 
      else {
        // Handle errors thrown from backend
        if (err === 'LOGIN_BAD_CREDENTIALS') {
          setError('Incorrect credentials');
        }
        else {
          setError('Error occured in the API.');
        }
      }
    }
  };

  // const [cardClasses, setCardClasses] = useState("");

  const [cardClasses, setCardClasses] = useState("card-hidden");

  useEffect(() => {
    const timer = setTimeout(() => {
      setCardClasses("");
    }, 100);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <div className="content d-flex align-items-center p-0">
        <Container>
          <Col className="mx-auto" lg="4" md="8">
            <Form action="" className="form" method="">
              <Card className={"card-login " + cardClasses}>
                <Card.Header>
                  <h3 className="header text-center">Verify Email</h3>
                </Card.Header>
                <Card.Body>
                  <Row className="justify-content-center">
                    <Col>
                      <p>A verification code was sent to {email}.
                      Click the link to verify your account and then
                      navigate to the login page.</p>
                    </Col>
                  </Row>

                </Card.Body>
                <Card.Footer className="ml-auto mr-auto">

                  <Row className="align-items-center justify-content-center">
                    <Button
                      className="btn-wd login-btn"
                      onClick={callSubmit}
                    >
                      Resend Verification
                    </Button>
                  </Row>
                  <Row className="align-items-center justify-content-center">
                    <Button
                      className="btn-wd login-btn"
                      onClick={async (e) => {
                        e.preventDefault();
                        await authAPI.signOut();
                        history.push("/auth/login");
                      }}
                    >
                      Sign in
                    </Button>
                  </Row>
                </Card.Footer>
              </Card>
            </Form>
          </Col>
        </Container>
      </div>
    </>
  );
}

export default VerifyPage;