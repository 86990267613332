import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function ScrollToTop() {
  const { pathname } = useLocation();

  // useEffect(() => {
  //   console.log(pathname);
  //   window.scrollTo(0, 0);
  // }, [pathname]);
  useEffect(() => {
    const container = document.getElementById('main-wrapper-scrollable-container');
    if (container) {
      container.scrollTop = 0;
    }
  }, [pathname]); // Assuming pathname is a dependency that changes on route change

  return null;
}

export default ScrollToTop;