import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useUserContext } from '../../contexts/UserContext';
import TurrimLayout from '../../layouts/TurrimLayout.js';


const ProtectedRoute = ({ component: Component, ...rest }) => {
  const {
    currentUser,
    authenticating,
  } = useUserContext();


  if (authenticating) {

    return null;
  }


  if (!currentUser) {

    return <Redirect to='/auth/login' />;

  }

  if (currentUser && !currentUser.emailVerified) {
    return <Redirect to='/auth/verify' />;
  }

  // Render the component only when the user is signed in and authentication status is confirmed
  return (
    <Route {...rest} render={(props) => <TurrimLayout {...props} />} />
  );
};

export default ProtectedRoute;
