import React from 'react';
import { Card, Container, Row, Col } from 'react-bootstrap';

function CampaignInvitationSelectionModal({ campaigns, onSelect, invitations, onSelectInvitation }) {
    // Handle click on a campaign card
    const handleClick = (index) => {
        onSelect(index.toString());
    };

    const handleInvitationClick = (index) => {
        onSelectInvitation(index.toString());
    };

    // const numberOfDuplicates = 8;
    // let duplicatedcampaigns = [];

    // if (campaigns.length > 0) {
    //     const firstcampaigns = campaigns[0];

    //     for (let i = 0; i < numberOfDuplicates; i++) {
    //     duplicatedcampaigns.push({ ...firstcampaigns });
    //     }
    // }

    // // const numberOfDuplicates = 8;
    // let duplicatedinvitations = [];

    // if (invitations.length > 0) {
    //     const firstinvitations = invitations[0];

    //     for (let i = 0; i < numberOfDuplicates; i++) {
    //     duplicatedinvitations.push({ ...firstinvitations });
    //     }
    // }

    return (
        <>
            <div className="d-flex justify-content-center align-items-center p-0">
                <Card style={{ width: '475px', backgroundColor: '#efeff2' }}>
                    <Card.Title
                        as="h4"
                        style={{ padding: '10px' }}
                    // style={{ fontSize: "18px", color: "rgb(107,114,128)" }}
                    >
                        Select a Campaign or Invitation
                    </Card.Title>
                    <hr className="horizontal-divider" style={{ marginTop: "0px", marginBottom: '15px' }} />

                    <Row className="justify-content-center">
                        <Card.Title
                            style={{ maxWidth: '475px', width: '100%', padding: '0px 10px 10px', fontSize: "18px" }}
                        >
                            Campaigns
                        </Card.Title>
                        {campaigns?.length > 0 ? (
                            <Card
                                className="card-scroll-div-wrapper"
                                style={{ backgroundColor: 'transparent', borderRadius: '4px', margin: '0', borderWidth: '0', maxHeight: '300px', height: 'auto', maxWidth: '425px', width: '100%', paddingLeft: '0px', paddingRight: '0px' }}
                            // style={{ backgroundColor: 'transparent', borderRadius: '4px', margin: '0', borderWidth: '0', maxHeight: '800px', height: '100vh', maxWidth: '675px', width: '100%', paddingLeft: '0px', paddingRight: '0px' }}
                            >
                                <Card.Body
                                    style={{ paddingLeft: '10px', paddingTop: '5px', paddingRight: '10px', paddingBottom: '0px', width: '100%' }}
                                >
                                    {campaigns.map((campaign, index) => {
                                        // {duplicatedcampaigns.map((campaign, index) => {

                                        return (
                                            <Card
                                                key={index}
                                                className="campaign-selection-card"
                                                onClick={() => handleClick(index)}
                                                style={{ cursor: 'pointer' }}
                                            >
                                                <Card.Body style={{ padding: '0px' }}>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: '10px' }}>
                                                        <Card.Title
                                                            style={{ fontSize: "18px" }}
                                                        >
                                                            {campaign.campaign_name}
                                                        </Card.Title>
                                                    </div>
                                                    {/* <Card.Title>{campaign.candidate_name}</Card.Title> */}
                                                    <Row>
                                                        <Col>

                                                            <Card.Text>
                                                                Candidate: {campaign.candidate_name}<br />
                                                            </Card.Text>
                                                        </Col>
                                                        <Col>
                                                            <Card.Text>
                                                                Title: {campaign.title}<br />
                                                                Joined: {campaign.date_joined}
                                                            </Card.Text>
                                                        </Col>
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                        );

                                    })}
                                </Card.Body>
                            </Card>
                        ) : (
                            <div className="text-center" style={{ padding: '15px 15px 35px 15px' }}>
                                No campaigns found.
                            </div>
                        )}
                    </Row>



                    <Row className="justify-content-center">
                        <hr className="horizontal-divider" style={{ maxWidth: '475px', width: '100%', marginTop: "0px", marginBottom: '15px' }} />
                        <Card.Title
                            style={{ maxWidth: '475px', width: '100%', padding: '0px 10px 10px', fontSize: "18px" }}
                        >
                            Invitations
                        </Card.Title>
                        {invitations?.length > 0 ? (
                            <Card
                                className="card-scroll-div-wrapper"
                                style={{ backgroundColor: 'transparent', borderRadius: '4px', margin: '0', borderWidth: '0', maxHeight: '300px', height: 'auto', maxWidth: '425px', width: '100%', paddingLeft: '0px', paddingRight: '0px' }}
                            // style={{ backgroundColor: 'transparent', borderRadius: '4px', margin: '0', borderWidth: '0', maxHeight: '800px', height: '100vh', maxWidth: '675px', width: '100%', paddingLeft: '0px', paddingRight: '0px' }}
                            >
                                <Card.Body
                                    style={{ paddingLeft: '10px', paddingTop: '5px', paddingRight: '10px', paddingBottom: '0px', width: '100%' }}
                                >
                                    {invitations.map((invitation, index) => {
                                        // {duplicatedinvitations.map((invitation, index) => {
                                        // console.log("invitation: ", invitation);

                                        return (
                                            <Card
                                                key={index}
                                                className="campaign-selection-card"
                                                onClick={() => handleInvitationClick(index)}
                                                style={{ cursor: 'pointer' }}
                                            >
                                                <Card.Body style={{ padding: '0px' }}>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: '10px' }}>
                                                        <Card.Title
                                                            style={{ fontSize: "18px" }}
                                                        >
                                                            {invitation.campaign_name}
                                                        </Card.Title>
                                                    </div>
                                                    <Card.Text>
                                                        Candidate: {invitation.candidate_name}<br />
                                                    </Card.Text>
                                                    <Row style={{ paddingBottom: '5px' }}>
                                                        <Col>
                                                            <Card.Text>
                                                                From: {invitation.sender_email}
                                                            </Card.Text>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col>
                                                            <Card.Text>
                                                                Offer Title: {invitation.offer_title}
                                                            </Card.Text>
                                                        </Col>
                                                    </Row>
                                                    {/* <Row>
                                                        <Col>
                                                            <Card.Text>
                                                                Message: {invitation.message}
                                                            </Card.Text>
                                                        </Col>
                                                    </Row> */}
                                                </Card.Body>
                                            </Card>
                                        );

                                    })}
                                </Card.Body>
                            </Card>
                        ) : (
                            <div className="text-center" style={{ padding: '15px 15px 35px 15px' }}>
                                No invitations found.
                            </div>
                        )}
                    </Row>

                </Card>
            </div>
        </>
    );
}

export default CampaignInvitationSelectionModal;




// import React from 'react';
// import Select from 'react-select';

// import Container from 'react-bootstrap/Container';
// import Col from 'react-bootstrap/Col';
// import Card from 'react-bootstrap/Card';
// import Row from 'react-bootstrap/Row';
// import { useUserContext } from "../../contexts/UserContext";


// function CampaignSelectionModal({ campaigns, onSelect }) {
//     // Prepare options for React Select
//     const options = campaigns.map((campaign, index) => ({
//         value: index.toString(),
//         label: `${campaign.campaign_name} - ${campaign.candidate_name}`,
//     }));

//     // Handle selection change
//     const handleChange = (selectedOption) => {
//         onSelect(selectedOption ? selectedOption.value : null);
//     };

//     return (
//         <>
//             <div className="content d-flex align-items-center p-0">
//                 <Container>
//                     <Col className="mx-auto" lg="4" md="8">
//                         <Card className="card-login" style={{ paddingBottom: '5px' }}>
//                             <Card.Header style={{ marginTop: '0px' }}>
//                                 <h3 className="header text-center" style={{ marginTop: '0px' }}>Choose a Campaign or Invitation</h3>
//                             </Card.Header>
//                             <Card.Body className="align-items-center">
//                                 <Row className="justify-content-center">
//                                     {/* <Select
//                                         options={options}
//                                         onChange={handleChange}
//                                         placeholder="Select a Campaign..."
//                                         isClearable
//                                         isSearchable
//                                     /> */}
//                                     <Select
//                                         className="react-select dark"
//                                         classNamePrefix="react-select"
//                                         name="campaign-select"
//                                         // value={{ label: member.title, value: member.title }}
//                                         onChange={handleChange}
//                                         options={options}
//                                         placeholder="Select a Campaign..."
//                                         styles={{
//                                             control: (base) => ({
//                                                 ...base,
//                                                 backgroundColor: 'transparent',
//                                                 color: 'grey',
//                                                 borderColor: 'grey', // Add if you want the border grey as well
//                                                 minWidth: '200px',

//                                             }),
//                                             singleValue: (base) => ({
//                                                 ...base,
//                                                 color: 'grey',
//                                             }),
//                                             placeholder: (base) => ({
//                                                 ...base,
//                                                 color: 'grey',
//                                             }),
//                                             valueContainer: (base) => ({
//                                                 ...base,
//                                                 justifyContent: 'center', // Center content horizontally
//                                                 alignItems: 'center', // Center content vertically
//                                                 // width: '100px',
//                                                 backgroundColor: 'transparent',
//                                                 padding: '0px',
//                                             }),
//                                             menuList: (base) => ({
//                                                 ...base,
//                                                 "::-webkit-scrollbar": {
//                                                     width: "7px",
//                                                     height: "0px",
//                                                     position: "fixed",
//                                                     right: "0",
//                                                 },
//                                                 "::-webkit-scrollbar-track": {
//                                                     backgroundColor: "rgba(170, 170, 170, 0.616)",
//                                                     borderRadius: "9px",
//                                                 },
//                                                 "::-webkit-scrollbar-thumb": {
//                                                     background: "#aaa",
//                                                     borderRadius: "9px",
//                                                 },
//                                                 "::-webkit-scrollbar-thumb:hover": {
//                                                     background: "#555"
//                                                 }
//                                             })
//                                         }}
//                                     />
//                                     <button
//                                         onClick={() => onSelect(null)}
//                                         style={{
//                                             marginTop: 20,
//                                             borderRadius: 5,
//                                             backgroundColor: '#f0ad4e',
//                                             color: '#fff',
//                                             border: 'none',
//                                             width: '200px',
//                                             height: '40px',
//                                         }}
//                                     >
//                                         Select
//                                     </button>
//                                 </Row>
//                             </Card.Body>
//                         </Card>
//                     </Col>
//                 </Container>

//             </div>
//         </>
//     );
// }

// export default CampaignSelectionModal;
