import React, { useContext, useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import PropTypes from "prop-types";

import SidebarContext from "../../contexts/SidebarContext";
import { useUserContext } from "../../contexts/UserContext";

import Collapse from 'react-bootstrap/Collapse';
import Nav from 'react-bootstrap/Nav';



function Sidebar({ routes, image, background }) {
  const { user } = useUserContext();

  let location = useLocation();


  const { isSidebarMini, toggleSidebar } = useContext(SidebarContext);
  const [isHovered, setIsHovered] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  const handleMouseEnter = () => {
    setIsExpanded(true);
  };

  const handleMouseLeave = () => {
    setIsExpanded(false);
  };



  const hrStyle = {
    width: isSidebarMini && !isExpanded ? '55px' : '235px',
    margin: '3px 15px 0px 10px',
  };

  const navLinkStyle = {
    width: isSidebarMini && !isExpanded ? '55px' : '235px',
    margin: '3px 15px 10px 10px',
    // paddingBottom: '10px',
  };


  const [state, setState] = useState({});


  const getCollapseStates = (routes) => {
    let initialState = {};
    routes.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: getCollapseInitialState(prop.views),
          ...getCollapseStates(prop.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };

  const getCollapseInitialState = (routes) => {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (location.pathname === routes[i].layout + routes[i].path) {
        return true;
      }
    }
    return false;
  };
  // this function creates the links and collapses that appear in the sidebar (left menu)
  const createLinks = (routes) => {
    return routes.map((prop, key) => {
      if (prop.redirect) {
        return null;
      }
      if (prop.collapse) {
        var st = {};
        st[prop["state"]] = !state[prop.state];
        return (
          <Nav.Item
            className={getCollapseInitialState(prop.views) ? "active" : ""}
            as="li"
            key={key}
          >
            <Nav.Link
              className={state[prop.state] ? "collapsed" : ""}
              data-toggle="collapse"
              onClick={(e) => {
                e.preventDefault();
                setState({ ...state, ...st });
              }}
              aria-expanded={state[prop.state]}
            >
              <i className={prop.icon}>{prop.icon_name}</i>
              <p>
                {prop.name} <b className="caret"></b>
              </p>
            </Nav.Link>
            <Collapse in={state[prop.state]}>
              <div>
                <Nav as="ul">{createLinks(prop.views)}</Nav>
              </div>
            </Collapse>
          </Nav.Item>
        );
      }
      return (
        <Nav.Item
          className={activeRoute(prop.layout + prop.path)}
          key={key}
          as="li"
        >
          <Nav.Link
            to={prop.layout + prop.path}
            as={Link}
          >
            {prop.icon ? (
              <>
                <i className={prop.icon}>{prop.icon_name}</i>
                <p>{prop.name}</p>
              </>
            ) : (
              <>
                <span className="sidebar-mini">{prop.mini}</span>
                <span className="sidebar-normal">{prop.name}</span>
              </>
            )}
          </Nav.Link>
        </Nav.Item>
      );
    });
  };
  const activeRoute = (routeName) => {
    return location.pathname === routeName ? "active" : "";
  };

  useEffect(() => {
    setState(getCollapseStates(routes));
  }, []);

  const handleMiniLegalDocumentsClick = () => {
    toggleSidebar();
  };


  return (
    <>
      <div
        // className="sidebar"
        className={`sidebar ${isSidebarMini ? 'sidebar-mini' : ''} ${isExpanded ? 'expanded' : ''}`}
        style={{ zIndex: 9999 }}
        data-color={background}
        data-image={image}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className="sidebar-wrapper" style={{ paddingBottom: '0px', height: 'calc(100vh - 130px)', }}>

          <div className="logo">
            <a
              className="d-flex align-items-center simple-text logo-mini"
              style={{ height: '50px', paddingTop: "0px", paddingBottom: "0px" }}
              href="https://www.turrim.io"
              onClick={(e) => {
                e.preventDefault(); // Prevent default link behavior
                window.open('https://www.turrim.io', '_blank', 'noopener,noreferrer'); // Open in new tab
              }}
              rel="noopener noreferrer" // Security measure for links opening in a new tab
            >
              <div className="logo-img">
                <img
                  style={{ width: '40px' }}
                  // src={require("../../assets/img/tower-grey.svg").default}
                  // src={require("../../assets/img/logo/turrim-L-256x256.svg").default}
                  src={require("../../assets/img/logo/turrim-L-logo-rounded-border.svg").default}
                  alt="TRRM"
                />
              </div>
            </a>
            <a
              className="d-flex justify-content-start align-items-end simple-text logo-normal"
              style={{ height: '50px', paddingTop: "0px", paddingBottom: "3px" }}
              href="https://www.turrim.io"
              onClick={(e) => {
                e.preventDefault(); // Prevent default link behavior
                window.open('https://www.turrim.io', '_blank', 'noopener,noreferrer'); // Open in new tab
              }}
              rel="noopener noreferrer" // Security measure for links opening in a new tab
            >
              {/* Turrim */}
              {/* <div className="logo-img"> */}
                <img
                  style={{ width: '145px' }}
                  // src={require("../../assets/img/tower-grey.svg").default}
                  // src={require("../../assets/img/logo/turrim-L-256x256.svg").default}
                  src={require("../../assets/img/logo/turrim-labs-text.svg").default}
                  alt="TRRM"
                />
              {/* </div> */}
            </a>
          </div>

          <Nav as="ul">{createLinks(routes)}</Nav>

          <div
            className="d-flex justify-content-end flex-column"
            style={{
              // minHeight: (user && ((user.title === 'Manager') || (user.title === 'Director'))) ? 'calc(100vh - 536px)' : 'calc(100vh - 316px)',
              minHeight: (user && ((user.title === 'Manager') || (user.title === 'Director'))) ? 'calc(100vh - 536px)' : (user && ((user.title === 'Staff') || (user.title === 'Volunteer'))) ? 'calc(100vh - 316px)' : 'calc(100vh - 96px)',
            }}
          >
            <hr
              className="horizontal-divider"
              style={hrStyle}
            />

            {(isSidebarMini && !isExpanded) && (

              <div
                className="mini-legal-documents visible-on-sidebar-mini text-start"
                onClick={handleMiniLegalDocumentsClick}
                style={navLinkStyle}
              >
                <i className="fas fa-ellipsis-h">
                </i>
              </div>
            )}

            {((isSidebarMini && isExpanded) || (!isSidebarMini)) && (

              <Nav
                as="ul"
                style={{
                }}
              >
                <Nav.Item as="li">
                  <Nav.Link
                    href="https://www.turrim.io/privacy-policy"
                    onClick={(e) => {
                      e.preventDefault(); // Prevent the default action
                      window.open('https://www.turrim.io/privacy-policy', '_blank', 'noopener,noreferrer'); // Open the URL in a new tab
                    }}
                    rel="noopener noreferrer" // Security measure for links opening in a new tab
                    style={{
                      width: "235px",
                      margin: "3px 15px 0px 10px",
                      cursor: "pointer", // Change the cursor to indicate this is clickable
                    }}
                  >
                    <p>Privacy Policy</p>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Nav.Link
                    href="https://www.turrim.io/terms-and-conditions"
                    onClick={(e) => {
                      e.preventDefault(); // Prevent the default action
                      window.open('https://www.turrim.io/terms-and-conditions', '_blank', 'noopener,noreferrer'); // Open the URL in a new tab
                    }}
                    rel="noopener noreferrer" // Security measure for links opening in a new tab
                    style={{
                      width: "235px",
                      margin: "3px 15px 0px 10px",
                      cursor: "pointer", // Change the cursor to indicate this is clickable
                    }}
                  >
                    <p>Terms & Conditions</p>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Nav.Link
                    href="https://www.turrim.io/disclaimer"
                    onClick={(e) => {
                      e.preventDefault(); // Prevent the default action
                      window.open('https://www.turrim.io/disclaimer', '_blank', 'noopener,noreferrer'); // Open the URL in a new tab
                    }}
                    rel="noopener noreferrer" // Security measure for links opening in a new tab
                    style={{
                      width: "235px",
                      margin: "3px 15px 10px 10px",
                      cursor: "pointer", // Change the cursor to indicate this is clickable
                    }}
                  >
                    <p>Disclaimer</p>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            )}

          </div>
        </div>
      </div>
    </>
  );
}

let linkPropTypes = {
  // path: PropTypes.string,
  // layout: PropTypes.string,
  // name: PropTypes.string,
  // component: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
  path: PropTypes.string.isRequired,
  layout: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  icon: PropTypes.string,
  icon_name: PropTypes.string,
  mini: PropTypes.string,
  component: PropTypes.elementType, // Changed from oneOfType to elementType
};

Sidebar.defaultProps = {
  image: "",
  background: "black",
  routes: [],
};

// Sidebar.propTypes = {
//   image: PropTypes.string,
//   background: PropTypes.oneOf([
//     "black",
//     "azure",
//     "green",
//     "orange",
//     "red",
//     "purple",
//   ]),
//   routes: PropTypes.arrayOf(
//     PropTypes.oneOfType([
//       PropTypes.shape({
//         ...linkPropTypes,
//         icon: PropTypes.string,
//         icon_name: PropTypes.string,
//       }),
//       PropTypes.shape({
//         collapse: true,
//         path: PropTypes.string,
//         name: PropTypes.string,
//         state: PropTypes.string,
//         icon: PropTypes.string,
//         icon_name: PropTypes.string,
//         views: PropTypes.shape({
//           ...linkPropTypes,
//           mini: PropTypes.string,
//         }),
//       }),
//     ])
//   ),
// };

Sidebar.propTypes = {
  image: PropTypes.string,
  background: PropTypes.oneOf(["black", "azure", "green", "orange", "red", "purple"]),
  routes: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape(linkPropTypes),
      PropTypes.shape({
        collapse: PropTypes.bool,
        path: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        state: PropTypes.string.isRequired,
        icon: PropTypes.string,
        icon_name: PropTypes.string,
        views: PropTypes.arrayOf(PropTypes.shape(linkPropTypes)), // This ensures views is an array of objects conforming to linkPropTypes
      }),
    ])
  ),
};

export default Sidebar;