import React from "react";

// Lazy loading route components
const Dashboard = React.lazy(() => import("./views/Dashboard.js"));
const VoterGroupDashboard = React.lazy(() => import("./views/Voter/Group/VoterGroupDashboard.js"));
const WalkbookDashboard = React.lazy(() => import("./views/Walkbook/WalkbookDashboard.js"));
const RoadSignDashboard = React.lazy(() => import("./views/RoadSign/RoadSignDashboard.js"));
const RecommendationDashboard = React.lazy(() => import("./views/Recommendations/Walkbook/RecommendationDashboard.js"));
const AccountView = React.lazy(() => import("./views/User/AccountView.js"));
const CampaignPage = React.lazy(() => import("./views/Campaign/CampaignPage.js"));
const DistrictPage = React.lazy(() => import("./views/District/DistrictPage.js"));



export const managerDirectorRoutes = [
  {
    path: "/dashboard",
    layout: "/turrim",
    name: "Dashboard",
    icon: "material-icons-round",
    icon_name: "grid_view",
    component: Dashboard,
  },
  {
    path: "/walkbooks",
    layout: "/turrim",
    name: "Walkbooks",
    icon: "material-icons-round",
    icon_name: "route",
    component: WalkbookDashboard,
  },
  {
    path: "/road-signs",
    layout: "/turrim",
    name: "Road Signs",
    icon: "material-icons-round",
    icon_name: "signpost",
    component: RoadSignDashboard,
  },
  {
    path: "/recommendations",
    layout: "/turrim",
    name: "Recommendations",
    icon: "material-icons-round",
    icon_name: "insights",
    component: RecommendationDashboard,
  },
  {
    path: "/voter-groups",
    layout: "/turrim",
    name: "Voter Groups",
    icon: "material-icons-round",
    icon_name: "group",
    component: VoterGroupDashboard,
  },
  {
    path: "/district",
    layout: "/turrim",
    name: "District",
    icon: "material-icons-round",
    icon_name: "landscape",
    component: DistrictPage,
  },
  {
    path: "/campaign",
    layout: "/turrim",
    name: "Campaign",
    icon: "material-icons-round",
    icon_name: "castle",
    component: CampaignPage,
  },
  {
    path: "/account",
    layout: "/turrim",
    name: "Account",
    icon: "material-icons-round",
    icon_name: "person",
    component: AccountView,
  }
];


export const staffVolunteerRoutes = [
  {
    path: "/walkbooks",
    layout: "/turrim",
    name: "Walkbooks",
    icon: "material-icons-round",
    icon_name: "route",
    component: WalkbookDashboard,
  },
  {
    path: "/road-signs",
    layout: "/turrim",
    name: "Road Signs",
    icon: "material-icons-round",
    icon_name: "signpost",
    component: RoadSignDashboard,
  },
  {
    path: "/campaign",
    layout: "/turrim",
    name: "Campaign",
    icon: "material-icons-round",
    icon_name: "castle",
    component: CampaignPage,
  },
  {
    path: "/account",
    layout: "/turrim",
    name: "Account",
    icon: "material-icons-round",
    icon_name: "person",
    component: AccountView,
  }
];