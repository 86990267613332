class RecommendedWalkbook {

  constructor() {
    this.baseURL = process.env.REACT_APP_API_BASE_URL;
  }



  requestRecommendedWalkbooks = async (token, campaign_id, campaign_name, user_id, request_name, description, filterParams) => {
    console.log(filterParams);
    // const localUser = JSON.parse(localStorage.getItem('user')) ?? {};
    // const token = localUser['oauth_account']['access_token'];
    // const token = localStorage.getItem('firebase_token');

    // Create data JSON
    const formData = {
      "campaign_id": campaign_id,
      "campaign_name": campaign_name,
      "user_id": user_id,
      "request_name": request_name,
      "description": description,
      "outreach_type": "Walkbook",
      "creation_method": "Requested",
      "filter_params": filterParams
    };

    console.log(formData);

    if (token) {
      const request = new Request(`${this.baseURL}/recommended-walkbook/request-walkbook-recommendation/`, {
        method: 'POST',
        headers: {
          'accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'X-client_type': 'web'
        },
        body: JSON.stringify(formData),
        credentials: 'include',
      });

      // Fetch request
      const response = await fetch(request);
      // 500 error handling
      if (response.status === 500) {
        throw new Error('Internal server error');
      }
      // 400 error handling
      const data = await response.json();
      if (response.status >= 400 && response.status < 500) {
        if (data.detail) {
          throw data.detail;
        }
        throw data;
      }
      return data;
    } else {
      console.error("No token found");
    }
  };


  getRecommendation = async (token, id, campaign_id) => {
    //const token = localStorage.getItem('token');
    // const localUser = JSON.parse(localStorage.getItem('user')) ?? {};
    // const token = localUser['oauth_account']['access_token'];
    // const token = localStorage.getItem('firebase_token');

    if (token) {
      if (id && campaign_id) {
        // Create request
        const request = new Request(`${this.baseURL}/recommended-walkbook/recommendation/?id=` + id + '&campaign_id=' + campaign_id, {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Authorization': `Bearer ${token}`,
            'X-client_type': 'web'
          }
          //headers: {'Authorization': `Bearer ${token}`}
        });
        // Fetch request
        const response = await fetch(request);
        const data = await response.json();
        //console.log(data);
        return data;
      }
      else {
        throw new Error('ID was not provided');
      }
    } else {
      console.error("No token found");
    }
  };


  getAllRecommendations = async (token, campaign_id) => {
    //const token = localStorage.getItem('token');
    // const localUser = JSON.parse(localStorage.getItem('user')) ?? {};
    // const token = localUser['oauth_account']['access_token'];
    // const token = localStorage.getItem('firebase_token');

    if (token) {
      if (campaign_id) {
        // Create request
        const request = new Request(`${this.baseURL}/recommended-walkbook/recommendation/all/?campaign_id=` + campaign_id, {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Authorization': `Bearer ${token}`,
            'X-client_type': 'web'
          }
          //headers: {'Authorization': `Bearer ${token}`}
        });
        // Fetch request
        const response = await fetch(request);
        const data = await response.json();
        // localStorage.setItem('recommendedWalkbooks', JSON.stringify(data));
        // try {
        //   localStorage.setItem('recommendedWalkbooks', JSON.stringify(data));
        // } catch (error) {
        //   console.error("Error setting recommendedWalkbooks in localStorage:", error);
        // }
        // console.log(JSON.stringify(data));
        // console.log(data);
        return data;
      }
      else {
        throw new Error('ID or Name was not provided');
      }
    } else {
      console.error("No token found");
    }
  };






  createRecommendedWalkbook = async (token, name, creationMethod, geoData, filterParams, campaign_id, user_id) => { //,email) => {
    // console.log(name);
    // console.log(creationMethod);
    // // console.log(Walkbook_type);
    // // console.log(geo_type);
    // // console.log(geo_points);
    // // console.log(typeof geo_points);
    // // console.log(farm_id);
    // //  Assert name, Walkbook_type, geo_type, and geo_points are not empty
    // if (!((name.length) > 0)) {
    //  throw new Error('Name was not provided');
    // }
    // if (!((Walkbook_type.length) > 0)) {
    //   throw new Error('Walkbook type was not provided');
    // }
    // if (!((geo_type.length) > 0)) {
    //   throw new Error('Geography type was not provided');
    // }
    // if (!((geo_points.length) > 0)) {
    //   throw new Error('Geography points were not provided');
    // }
    // if (!((farm_id) > 0)) {
    //   throw new Error('Farm ID was not provided');
    // }

    // if (geo_type === "Point") {
    //   geo_points = [geo_points];
    // }
    // if (geo_type === ("Polygon" || "LineString")) {
    //   geo_points = geo_points[0];
    // }
    // console.log(typeof geo_points[0][0]);

    // const localUser = JSON.parse(localStorage.getItem('user')) ?? {};
    // const token = localUser['oauth_account']['access_token'];
    // const token = localStorage.getItem('firebase_token');

    // Create data JSON
    const formData = {
      "name": name,
      "creation_method": creationMethod,
      "geo_data": geoData[0],
      "filter_params": filterParams,
      // "voters": voters,
      "campaign_id": campaign_id,
      "user_id": user_id,
    };

    if (token) {
      const request = new Request(`${this.baseURL}/recommended-walkbook/`, {
        method: 'POST',
        headers: {
          'accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'X-client_type': 'web'
        },
        body: JSON.stringify(formData),
        credentials: 'include',
      });
      // Fetch request
      // const response = await fetch(request);
      // const data = await response.json();
      // return data

      // Fetch request
      const response = await fetch(request);
      // 500 error handling
      if (response.status === 500) {
        throw new Error('Internal server error');
      }
      // 400 error handling
      const data = await response.json();
      if (response.status >= 400 && response.status < 500) {
        if (data.detail) {
          throw data.detail;
        }
        throw data;
      }
      return data;
    } else {
      console.error("No token found");
    }
  };


  getRecommendedWalkbook = async (token, id, campaign_id) => {
    //const token = localStorage.getItem('token');
    // const localUser = JSON.parse(localStorage.getItem('user')) ?? {};
    // const token = localUser['oauth_account']['access_token'];
    // const token = localStorage.getItem('firebase_token');

    if (token) {
      if (id && campaign_id) {
        // Create request
        const request = new Request(`${this.baseURL}/recommended-walkbook/?id=` + id + '&campaign_id=' + campaign_id, {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Authorization': `Bearer ${token}`,
            'X-client_type': 'web'
          }
          //headers: {'Authorization': `Bearer ${token}`}
        });
        // Fetch request
        const response = await fetch(request);
        const data = await response.json();
        //console.log(data);
        return data;
      }
      else {
        throw new Error('ID was not provided');
      }
    } else {
      console.error("No token found");
    }
  };


  getAllRecommendedWalkbooks = async (token, campaign_id) => {
    //const token = localStorage.getItem('token');
    // const localUser = JSON.parse(localStorage.getItem('user')) ?? {};
    // const token = localUser['oauth_account']['access_token'];
    // const token = localStorage.getItem('firebase_token');

    if (token) {
      if (campaign_id) {
        // Create request
        const request = new Request(`${this.baseURL}/recommended-walkbook/all/?campaign_id=` + campaign_id, {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Authorization': `Bearer ${token}`,
            'X-client_type': 'web'
          }
          //headers: {'Authorization': `Bearer ${token}`}
        });
        // Fetch request
        const response = await fetch(request);
        const data = await response.json();
        // localStorage.setItem('recommendedWalkbooks', JSON.stringify(data));
        // try {
        //   localStorage.setItem('recommendedWalkbooks', JSON.stringify(data));
        // } catch (error) {
        //   console.error("Error setting recommendedWalkbooks in localStorage:", error);
        // }
        // console.log(JSON.stringify(data));
        // console.log(data);
        return data;
      }
      else {
        throw new Error('ID or Name was not provided');
      }
    } else {
      console.error("No token found");
    }
  };


  updateRecommendedWalkbook = async (token, id, name, geojson, status, filterParams, campaign_id, user_id) => {
    // const localUser = JSON.parse(localStorage.getItem('user')) ?? {};
    // const token = localUser['oauth_account']['access_token'];
    // const token = localStorage.getItem('firebase_token');

    const formData = {
      "id": id,
      "name": name,
      "creation_method": "Recommended",
      "status": status,
      "geojson": geojson,
      "filter_params": filterParams,
      "campaign_id": campaign_id,
      "user_id": user_id,
    };

    if (token) {
      if (id) {
        // Create request
        const request = new Request(`${this.baseURL}/recommended-walkbook/`, {
          method: 'PUT',
          headers: {
            'accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'X-client_type': 'web'
          },
          body: JSON.stringify(formData),
          credentials: 'include',
        });
        // Fetch request
        const response = await fetch(request);
        const data = await response.json();
        // 500 error handling
        if (response.status === 500) {
          throw new Error('Internal server error');
        }

        // 400 error handling
        if (response.status >= 400 && response.status < 500) {
          // console.log("data.detail: ", data.detail); 
          if (data.detail) {
            throw new Error(data.detail);
          }

          // console.log("typeof data: ", typeof data);
          if (typeof data === 'string') {
            throw new Error(data);
          }

          if (typeof data === 'object') {
            if (data['error'] === 'NAME_EXISTS') {
              throw new Error('This name is already in use. Please choose a different name.');
            }
            // throw new Error(data);
          }

          throw new Error('An error occurred');
        }

        return data;

      } else {
        throw new Error('ID or Name was not provided');
      }
    } else {
      console.error("No token found");
    }
  };


  deleteRecommendedWalkbook = async (token, id, campaign_id) => {
    // console.log(id);
    // console.log(campaign_id);
    // const localUser = JSON.parse(localStorage.getItem('user')) ?? {};
    // const token = localUser['oauth_account']['access_token'];
    // const token = localStorage.getItem('firebase_token');

    if (token) {
      if (id && campaign_id) {
        // console.log("Here 3");
        // Create request
        const request = new Request(`${this.baseURL}/recommended-walkbook/?id=` + id + '&campaign_id=' + campaign_id, {
          method: 'DELETE',
          credentials: 'include',
          headers: {
            'Authorization': `Bearer ${token}`,
            'X-client_type': 'web'
          }
          //headers: {'Authorization': `Bearer ${token}`}
        });
        // Fetch request
        const response = await fetch(request);
        // console.log("Here 4");
        // console.log(response.status);


        if (response.status === 204) {
          return true;
        } else {
          const data = await response.json();
          if (data.detail) {
            throw data.detail;
          }
          throw data;
        }
      } else {
        throw new Error('Walkbook Name and/or Campaign_id was not provided');
      }
    } else {
      console.error("No token found");
    }
  };

}

export default new RecommendedWalkbook();