import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyC3DQcGSV9eEzwZYMEZTxMV20-7roH-A1g",
  authDomain: "turrim-389005.firebaseapp.com",
  projectId: "turrim-389005",
  storageBucket: "turrim-389005.appspot.com",
  messagingSenderId: "424787656378",
  appId: "1:424787656378:web:c6a84e2e4884a381f84c83",
  measurementId: "G-489K7P69SP"
};

// Initialize Firebase
const firebase = initializeApp(firebaseConfig);

const auth = getAuth();

export { firebase, auth };

