import React from "react";
import { Link, useLocation } from "react-router-dom";

// react-bootstrap components
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

import { useUserContext } from "../../contexts/UserContext";

function AuthNavbar(props) {
  const {
    loading,
    setLoading,
    loadingCampaign,
    setLoadingCampaign,
    loadingWalkbooks,
    setLoadingWalkbooks,
    loadingRoadSigns,
    setLoadingRoadSigns,
    loadingRecommendations,
    setLoadingRecommendations,
    loadingVoterGroups,
    setLoadingVoterGroups
  } = useUserContext();
  const location = useLocation();

  return (
    <>
      <Navbar
        className="position-absolute w-100"
        expand="lg"
        variant="transparent"
      >
        <Container>
          <div className="navbar-wrapper">
            <Navbar.Brand
              href="https://www.turrim.io"
              onClick={(e) => {
                e.preventDefault(); // Prevent default link behavior
                window.open('https://www.turrim.io', '_blank', 'noopener,noreferrer'); // Open in new tab
              }}
              rel="noopener noreferrer" // Security measure for links opening in a new tab
            >
              <span className="d-none d-md-block">Turrim</span>
              <span className="d-block d-md-none">Turrim</span>
            </Navbar.Brand>
          </div>
          <Nav navbar>
            <div className="justify-content-end navbar-wrapper">
              {((location.pathname === "/turrim/user-options") || (location.pathname === "/turrim/terms")) ? (
                (loading !== null && loading !== "") ? (
                  null
                ) : (
                  <Nav.Item
                    className="active mr-1"
                  >
                    <Nav.Link
                      as={Link}
                      to="/auth/login"
                      // disabled={loading !== null && loading !== ""}
                      onClick={props.logout}
                    >
                      Sign out
                    </Nav.Link>
                  </Nav.Item>
                )
              ) : (
                <Nav.Item
                  className={
                    location.pathname === "/auth/login"
                      ? "active mr-1"
                      : "mr-1"
                  }
                >
                  <Nav.Link to="/auth/login" as={Link}>
                    <i className="nc-icon nc-mobile"></i>
                    Login
                  </Nav.Link>
                </Nav.Item>
              )}
            </div>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
}

export default AuthNavbar;