import { auth } from '../../contexts/Firebase';
import { createUserWithEmailAndPassword, updatePassword, sendEmailVerification, sendPasswordResetEmail, signInWithCustomToken, signInWithEmailAndPassword, signOut } from "firebase/auth";

class Auth {


  constructor() {
    this.baseURL = process.env.REACT_APP_API_BASE_URL;
  }



  // Helper function to validate string inputs
  validateStringInput = (input, name) => {
    if (!(input && input.length > 0)) {
      throw new Error(`${name} was not provided`);
    }
  };

  // Helper function to get current date as a string
  getCurrentDate = () => {
    const nDate = new Date();
    return `${nDate.getFullYear()}-${nDate.getMonth() + 1}-${nDate.getDate()}`;
  };

  // resetPassword = async (email) => {
  //   if (!email) {
  //     setError('Please enter your email address to reset your password.');
  //     return;
  //   }
  //   try {
  //     await sendPasswordResetEmail(auth, email);
  //     alert('Password reset email sent!');
  //   } catch (error) {
  //     console.error('Error sending password reset email:', error);
  //     setError('Failed to send password reset email. Please check your email and try again.');
  //   }
  // };


  signUpWithEmail = async (email, password) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;


      await sendEmailVerification(user);

      return user;
    } catch (error) {
      console.error("Error signing up: ", error);
    }
  };

  registerWithEmail = async (email, password) => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      await sendEmailVerification(user);

      return user;
    } catch (error) {
      console.error("Error signing up: ", error);
    }
  };

  // Method to sign in the user and update their password
  signInAndUpdatePassword = async (email, oldPassword, newPassword) => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, oldPassword);
      const user = userCredential.user;

      // Once signed in, update the user's password to the new one
      await updatePassword(user, newPassword);

      // Send an email verification if the user hasn't been verified yet
      await sendEmailVerification(user);

      return user;
    } catch (error) {
      console.error("Error updating password: ", error);
      throw error; // Rethrow or handle error appropriately
    }
  };

  resendVerificationEmail = async (user) => {
    console.log("Resending verification email for user: ", user);
    const currentUser = auth.currentUser;
    console.log("Current user: ", currentUser);
    try {
      await sendEmailVerification(currentUser);
      return true;
    } catch (error) {
      console.error("Error resending verification email: ", error);
      throw error; // Rethrow or handle error appropriately
    }
  };

  register = async (token, first, last, email, password, passwordConfirmation, firebaseUser) => {
    this.validateStringInput(first, 'First Name');
    this.validateStringInput(last, 'Last Name');
    this.validateStringInput(email, 'Email');
    this.validateStringInput(password, 'Password');
    this.validateStringInput(passwordConfirmation, 'Password Confirmation');

    if (password !== passwordConfirmation) {
      throw new Error('Passwords do not match');
    }

    const oauth_account = {
      "provider": firebaseUser.providerId,
      "oauth_name": firebaseUser.providerId,
      "access_token": firebaseUser.accessToken,
      "account_id": firebaseUser.uid,
      "account_email": firebaseUser.email,
      "account_verified": firebaseUser.emailVerified
    }

    const formData = {
      "first": first,
      "last": last,
      "email": email,
      "is_active": true,
      "is_superuser": false,
      "is_verified": false,
      "terms_accepted": false,

      "oauth_accounts": oauth_account
    };


    const request = new Request(`${this.baseURL}/users/`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    });

    const response = await fetch(request);

    if (response.status === 500) {
      throw new Error('Internal server error');
    }

    const data = await response.json();
    if (response.status >= 400 && response.status < 500) {
      throw data.detail || data;
    }

    return true;
  };


  signInWithEmail = async (email, password) => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      return user;
    } catch (error) {
      if (error.code === 'auth/wrong-password') {

        throw new Error('LOGIN_BAD_CREDENTIALS');

      } else if (error.code === 'auth/user-not-found') {
        console.error("User not found");

        throw new Error('LOGIN_NO_USER');

      } else {
        throw error;

      }
    }
  };

  updateUserTerms = async (token, terms_status) => {


    if (token) {
      try {
        const request = new Request(`${this.baseURL}/users/terms/?terms_status=` + terms_status, {
          method: 'PUT',
          headers: {
            'Authorization': `Bearer ${token}`  // Set Authorization header
          },
          credentials: 'include',
        });

        const response = await fetch(request);

        const data = await response.json();
        if (response.status >= 400 && response.status < 500) {
          if (data.detail) {
            throw data.detail;
          }
          throw data;
        }
        else {

          return data

        }
      } catch (error) {
        throw new Error('Failed to update user terms');

      }
    } else {
      console.error("No token found");

    }
  };


  removeUserFromCampaign = async (token, email, campaign_id) => {
    if (!email || !campaign_id) {
      throw new Error('Email or campaign_id was not provided');
    }

    if (token) {
      try {
        const request = new Request(`${this.baseURL}/users/remove/campaign-user/?email=${email}&campaign_id=${campaign_id}`, {
          method: 'PUT',
          headers: {
            'Authorization': `Bearer ${token}`  // Set Authorization header
          },
          credentials: 'include',
        });

        const response = await fetch(request);

        const data = await response.json();
        if (response.status >= 400 && response.status < 500) {
          if (data.detail) {
            throw data.detail;
          }
          throw data;
        }
        else {

          return data

        }
      } catch (error) {
        throw new Error('Failed to remove user from campaign');

      }
    } else {
      console.error("No token found");

    }
  };


  deleteUserAccount = async (token) => {
    // if (!id) {
    //   throw new Error('id was not provided');
    // }

    if (token) {
      try {
        const request = new Request(`${this.baseURL}/users/`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${token}`  // Set Authorization header
          },
          credentials: 'include',
        });

        const response = await fetch(request);

        const data = await response.json();
        if (response.status >= 400 && response.status < 500) {
          if (data.detail) {
            throw data.detail;
          }
          throw data;
        }
        else {

          return data

        }
      } catch (error) {
        throw new Error('Failed to delete user from all campaigns and Turrim');

      }
    } else {
      console.error("No token found");

    }
  };


  getUser = async (token) => {


    if (token) {
      try {
        const request = new Request(`${this.baseURL}/users/me`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`  // Set Authorization header
          },
          credentials: 'include',
        });

        const response = await fetch(request);

        const data = await response.json();
        if (response.status >= 400 && response.status < 500) {
          
          if (data.detail) {
            throw data.detail;
          }
          throw data;
        }
        else {
          
          return data

        }
      } catch (error) {

        throw new Error('Failed to fetch user');

      }
    }

  };


  getCampaignUser = async (token, campaign_id) => {


    if (token) {
      try {
        const request = new Request(`${this.baseURL}/users/campaign-account/?campaign_id=` + campaign_id, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`  // Set Authorization header
          },
          credentials: 'include',
        });

        const response = await fetch(request);

        const data = await response.json();
        if (response.status >= 400 && response.status < 500) {
          
          if (data.detail) {
            throw data.detail;
          }
          throw data;
        }
        else {

          return data

        }
      } catch (error) {

        throw new Error('Failed to fetch user');

      }
    } else {
      console.error("No token found");

    }
  };



  signOut = async () => {
    try {
      localStorage.clear();
      await signOut(auth);
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };
}

export default new Auth();