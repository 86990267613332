class Invitation {

  constructor() {
    this.baseURL = process.env.REACT_APP_API_BASE_URL;
  }


  createInvitation = async (token, sender_id, campaign_id, receiver_email, user_title) => {
    // const localUser = JSON.parse(localStorage.getItem('user')) ?? {};
    // const token = localUser['oauth_account']['access_token'];
    // const token = localStorage.getItem('firebase_token');

    const InvitationData = {
      "sender_id": sender_id,
      "campaign_id": campaign_id,
      "receiver_email": receiver_email,
      "offer_title": user_title,
    };

    // console.log(InvitationData);

    if (token) {
      const request = new Request(`${this.baseURL}/invitation/`, {
        method: 'POST',
        headers: {
          'accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'X-client_type': 'web'
        },
        body: JSON.stringify(InvitationData),
        credentials: 'include',
      });
      // Fetch request
      const response = await fetch(request);
      // 500 error handling
      if (response.status === 500) {
        throw new Error('Internal server error');
      }
      // Extracting response data
      const data = await response.json();
      // console.log(data);
      // 400 error handling
      if (response.status >= 400 && response.status < 500) {
        if (data.detail) {
          throw data.detail;
        }
        throw data;
      }

      return data;
    } else {
      console.error("No token found");
    }
  };


  validateInvitationToken = async (invitationToken) => {
    if (invitationToken) {
      const request = new Request(`${this.baseURL}/validate-invitation/?token=` + invitationToken, {
        method: 'GET',
        credentials: 'include',
      });
      // Fetch request
      const response = await fetch(request);
      // 500 error handling
      if (response.status === 500) {
        throw new Error('Internal server error');
      }
      // Extracting response data
      const data = await response.json();
      // console.log(data);
      // 400 error handling
      if (response.status >= 400 && response.status < 500) {
        if (data.detail) {
          throw data.detail;
        }
        throw data;
      }

      return data;
    } else {
      console.error("No token found");
    }
  };


  getInvitation = async (token, receiver_email, campaign_id) => {
    //const token = localStorage.getItem('token');
    // const localUser = JSON.parse(localStorage.getItem('user')) ?? {};
    // const token = localUser['oauth_account']['access_token'];
    // const token = localStorage.getItem('firebase_token');

    let request;

    if (token) {
      if (receiver_email) {
        if (campaign_id) {
          request = new Request(`${this.baseURL}/invitation/?receiver_email=` + receiver_email + '&campaign_id=' + campaign_id, {
            method: 'GET',
            credentials: 'include',
            headers: {
              'Authorization': `Bearer ${token}`,
              'X-client_type': 'web'
            }
          });

        } else {
          request = new Request(`${this.baseURL}/invitation/?receiver_email=` + receiver_email, {
            method: 'GET',
            credentials: 'include',
            headers: {
              'Authorization': `Bearer ${token}`,
              'X-client_type': 'web'
            }
          });

        }

        // Fetch request
        const response = await fetch(request);
        if (response.status === 500) {
          throw new Error('Internal server error');
          // return null;
        }
        // console.log(response.json());
        // Extracting response data
        const data = await response.json();
        // console.log(data);
        // console.log(data.detail);
        // 400 error handling
        if (response.status === 404) {
          // localStorage.setItem('invitation', JSON.stringify(null));
          return null;
        } else {
          // return null;
          if (response.status >= 400 && response.status < 500) {
            if (data.detail) {
              throw data.detail;
            }
            throw data;
          }
        }
        // localStorage.setItem('invitation', JSON.stringify(data));
        return data;
      }
      else {
        throw new Error('ID or Name was not provided');
      }
    } else {
      console.error("No token found");
    }
  };

  // getInvitations = async (receiver_email) => {
  //   //const token = localStorage.getItem('token');
  //   // const localUser = JSON.parse(localStorage.getItem('user')) ?? {};
  //   // const token = localUser['oauth_account']['access_token'];
  //   const token = localStorage.getItem('firebase_token');

  //   if (token) {
  //     if (receiver_email) {
  //       // Create request
  //       // const request = new Request('https://api.turrim-labs.com/note-board/?id=' + boardID, {
  //       const request = new Request(`${this.baseURL}/invitation/all/?receiver_email=` + receiver_email, {
  //         method: 'GET',
  //         credentials: 'include',
  //         headers: {
  //           'Authorization': `Bearer ${token}`,
  //           'X-client_type': 'web'
  //         }
  //       });
  //       // Fetch request
  //       const response = await fetch(request);
  //       if (response.status === 500) {
  //         throw new Error('Internal server error');
  //       }
  //       // console.log(response.json());
  //       // Extracting response data
  //       const data = await response.json();
  //       // console.log(data);
  //       // console.log(data.detail);
  //       // 400 error handling
  //       if (response.status === 404) {
  //         localStorage.setItem('invitation', JSON.stringify(null));
  //         return null;
  //       } else {
  //         if (response.status >= 400 && response.status < 500) {
  //           if (data.detail) {
  //             throw data.detail;
  //           }
  //           throw data;
  //         }
  //       }
  //       localStorage.setItem('invitation', JSON.stringify(data));
  //       return data;
  //     }
  //     else {
  //       throw new Error('ID or Name was not provided');
  //     }
  //   } else {
  //     console.error("No token found");
  //   }
  // };


  updateInvitation = async (token, id, date_sent, sender_id, receiver_email, campaign_id, invite_status, message, offer_title) => {
    // const localUser = JSON.parse(localStorage.getItem('user')) ?? {};
    // const token = localUser['oauth_account']['access_token'];

    // console.log(`localUser: ${localUser['oauth_account']['access_token']}`);
    // const token = localStorage.getItem('firebase_token');

    const InvitationData = {
      // "id": id,
      "id": id,
      "date_sent": date_sent,
      "sender_id": sender_id,
      "campaign_id": campaign_id,
      "receiver_email": receiver_email,
      "status": invite_status,
      "message": message,
      "offer_title": offer_title
    };
    
    if (token) {
      const request = new Request(`${this.baseURL}/invitation/`, {
        method: 'PUT',
        headers: {
          'accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'X-client_type': 'web'
        },
        // body: JSON.stringify(NotesOrderData),
        body: JSON.stringify(InvitationData),
        // body: Invitation,
        credentials: 'include',
      });
      // Fetch request
      const response = await fetch(request);
      // 500 error handling
      if (response.status === 500) {
        throw new Error('Internal server error');
      }
      // Extracting response data
      const data = await response.json();
      // console.log(data);
      // 400 error handling
      if (response.status >= 400 && response.status < 500) {
        if (data.detail) {
          throw data.detail;
        }
        throw data;
      }
      // console.log(data);
      return data;
    } else {
      console.error("No token found");
    }
  };
}

export default new Invitation();