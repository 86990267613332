import React, { useContext } from "react";

import Container from 'react-bootstrap/Container';
import Button from "react-bootstrap/Button";
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { Link } from "react-router-dom";

import SidebarContext from "../../contexts/SidebarContext";


function TurrimNavbar(props) {
  const { toggleSidebar } = useContext(SidebarContext);

  return (
    <>
      <Navbar expand="lg">
        <Container fluid>
          <div className="navbar-wrapper">
            <div className="navbar-minimize">
              <Button
                className="btn-fill btn-round btn-icon d-none d-lg-block bg-dark border-dark"
                variant="dark"
                onClick={() => {
                  // console.log("toggleSidebar from navbar button click on lg screen");
                  toggleSidebar();
                  // document.documentElement.classList.toggle("nav-open");
                  // document.body.classList.toggle("sidebar-mini");
                  
                }}
              >
                <i className="fas fa-bars visible-on-sidebar-mini"></i>
                <i className="fas fa-ellipsis-v visible-on-sidebar-regular"></i>
              </Button>
              <Button
                className="btn-fill btn-round btn-icon d-block d-lg-none bg-dark border-dark"
                variant="dark"
                onClick={() => {
                  // console.log("toggleSidebar from navbar button click on up to lg screen");
                  toggleSidebar();
                  document.documentElement.classList.toggle("nav-open");
                  // toggleSidebar();

                }}
              >
                <i className="fas fa-bars visible-on-sidebar-mini"></i>
                <i className="fas fa-ellipsis-v visible-on-sidebar-regular"></i>
              </Button>
            </div>
          </div>
            <Nav className="justify-content-end">
              <Nav.Item>
                <Nav.Link
                  as={Link}
                  to="/auth/login"
                >
                  <Button
                    className="btn-text text-danger"
                    onClick={props.logout}
                    style={{backgroundColor: "transparent", border: "none"}}
                  >
                    Sign out
                  </Button>
                </Nav.Link>
              </Nav.Item>
            </Nav>
        </Container>
      </Navbar>
    </>
  );
}

export default TurrimNavbar;
