class Campaign {

  constructor() {
    this.baseURL = process.env.REACT_APP_API_BASE_URL;
  }

  join = async (token, user, campaignName) => {

    if (!(campaignName.length > 0)) {
      throw new Error('Campaign name was not provided');
    }
    const campaign = await this.getCampaign(null, campaignName);
    
    const formData = {
        "first": user.first,
        "last": user.last,
        "title": "Worker",
        "email": user.email,
        "password": user.password,
        "campaign_id": campaign.id
    };

    if (token) {
      const request = new Request(`${this.baseURL}/users/me`, {
        method: 'PATCH',
        headers: {
          'accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'X-client_type': 'web'
        },
        body: JSON.stringify(formData),
        credentials: 'include',
      });
      const response = await fetch(request);
      if (response.status === 500) {
        throw new Error('Internal server error');
      }
      const data = await response.json();
      if (response.status >= 400 && response.status < 500) {
        if (data.detail) {
          throw data.detail;
        }
        throw data;
      }
      
      return data;
    } else {
      console.error("No token found");
    }
  };


  register = async (token, campaignData) => {

    if (token) {
      const request = new Request(`${this.baseURL}/campaign/`, {
        method: 'POST',
        headers: {
          'accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'X-client_type': 'web'
        },
        body: JSON.stringify(campaignData),
        credentials: 'include',
      });
      const response = await fetch(request);
      if (response.status === 500) {
        throw new Error('Internal server error');
      }
      const data = await response.json();
      if (response.status >= 400 && response.status < 500) {
        if (data.detail) {
          throw data.detail;
        }
        throw data;
      }
      return data;
    } else {
      console.error("No token found");
    }
  };


  getCampaign = async (token, id) => {

    if (token) {
      if (id) {
        const request = new Request(`${this.baseURL}/campaign/?id=`+id, {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Authorization': `Bearer ${token}`,
            'X-client_type': 'web'
          }
        });
        const response = await fetch(request);
        const data = await response.json();
        return data;
      }
      else {
        throw new Error('ID was not provided');
      }
    } else {
      console.error("No token found");
    }
  };


  updateCampaign = async (token, id, name, address, location, owner) => {

    if (!(id > 0)) {
     throw new Error('Campaign ID was not provided');
    }
    // Create data JSON
    const formData = {
      "id": id,
      "name": name,
      "address": address,
      "location": location,
      "owner_email": owner,
    }

    if (token) {
      const request = new Request(`${this.baseURL}/campaign/`, {
        method: 'PUT',
        headers: {
          'accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'X-client_type': 'web'
        },
        body: JSON.stringify(formData),
        credentials: 'include',
      });

      const response = await fetch(request);
      if (response.status === 500) {
        throw new Error('Internal server error');
      }
      const data = await response.json();
      if (response.status >= 400 && response.status < 500) {
        if (data.detail) {
          throw data.detail;
        }
        throw data;
      }
      return data;
    } else {
      console.error("No token found");
    }
  };

}

export default new Campaign();