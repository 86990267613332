import { useState, useEffect, useRef } from 'react';
import { useUserContext } from "./UserContext";

import authAPI from '../apis/auth/auth';
import campaignAPI from '../apis/campaign/campaign';
import walkbookAPI from '../apis/walkbook/walkbook';
import roadSignAPI from '../apis/road_sign/road_sign';
import recommendedWalkbookAPI from '../apis/walkbook/recommended_walkbook';
import voterGroupAPI from '../apis/voter_group/voter_group';
import voterTagsAPI from '../apis/voter_tags/voter_tags';


const DataLoader = () => {
  const {
    setAuthenticated,
    setVerified,
    setLoading,
    currentUser,
    campaignUser,
    setUser,
    setInvitation,
    setCampaign,
    setWalkbooks,
    setRoadSigns,
    setRecommendations,
    setVoterGroups,
    setVoterTags,
    setLoadingCampaign,
    setLoadingWalkbooks,
    setLoadingRoadSigns,
    setLoadingRecommendations,
    setLoadingVoterGroups
  } = useUserContext();

  const [errorMessage, setErrorMessage] = useState('');
  const isMounted = useRef(false);

  const isValidData = (data, requiredProps) => {
    if (data === undefined) return false;
    for (const prop of requiredProps) {
      if (!(prop in data)) return false;
      const value = data[prop];
      if (value === undefined || (Array.isArray(value) && value.length === 0)) return false;
    }
    return true;
  };

  const safeSetState = (setStateFunction, data, requiredProps = []) => {
    if (isMounted.current && isValidData(data, requiredProps)) {
      setStateFunction(data);
    }
  };


  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    async function onLoad() {
      // console.log("DataLoader: onLoad");
      setErrorMessage('');

      if (currentUser && !currentUser.stsTokenManager.isExpired && currentUser.emailVerified) {
        const firebaseToken = await currentUser.getIdToken();
        if (isMounted.current) {
          safeSetState(setAuthenticated, true);
          safeSetState(setVerified, true);
          // // safeSetState(setLoading, "Reloading data...");
          // safeSetState(setLoadingCampaign, "Loading campaign...");
          // safeSetState(setLoadingWalkbooks, "Loading walkbooks...");
          // safeSetState(setLoadingRoadSigns, "Loading road signs...");
          // safeSetState(setLoadingRecommendations, "Loading recommendations...");
          // safeSetState(setLoadingVoterGroups, "Loading voter groups...");
        }



        try {
          const serverUser = await authAPI.getUser(firebaseToken);

          if (serverUser) {


            if (Object.keys(campaignUser).length > 0) {
              serverUser.campaign_id = campaignUser['campaign_id'] ? campaignUser['campaign_id'] : null;
              serverUser.title = campaignUser['title'] ? campaignUser['title'] : null;
              serverUser.date_joined = campaignUser['date_joined'] ? campaignUser['date_joined'] : null;
            }


            if (isMounted.current) {
              safeSetState(setLoading, "Reloading data...");
              safeSetState(setUser, serverUser);
            }

            if (serverUser.campaign_id) {

              if (isMounted.current) {
                safeSetState(setLoadingCampaign, "Loading campaign...");
                safeSetState(setLoadingWalkbooks, "Loading walkbooks...");
                safeSetState(setLoadingRoadSigns, "Loading road signs...");
                safeSetState(setLoadingRecommendations, "Loading recommendations...");
                safeSetState(setLoadingVoterGroups, "Loading voter groups...");
              }
              
          

              const serverCampaign = await campaignAPI.getCampaign(firebaseToken, serverUser.campaign_id);
              if (isMounted.current) {
                // safeSetState(setLoadingCampaign, "Loading campaign...");
                safeSetState(setCampaign, serverCampaign);
                safeSetState(setLoadingCampaign, "");
              }

              if (serverUser.title === 'Manager' || serverUser.title === 'Director') {
                const serverWalkbooks = await walkbookAPI.getAllWalkbooks(firebaseToken, serverUser.campaign_id);
                if (isMounted.current) {
                  // safeSetState(setLoadingWalkbooks, "Loading walkbooks...");
                  safeSetState(setWalkbooks, { type: 'REPLACE', newWalkbooks: serverWalkbooks }, ['newWalkbooks']);
                  safeSetState(setLoadingWalkbooks, "");

                }

                const serverRoadSigns = await roadSignAPI.getAllRoadSigns(firebaseToken, serverUser.campaign_id);
                if (isMounted.current) {
                  // safeSetState(setLoadingRoadSigns, "Loading road signs...");
                  safeSetState(setRoadSigns, { type: 'REPLACE', newRoadSigns: serverRoadSigns }, ['newRoadSigns']);
                  safeSetState(setLoadingRoadSigns, "");
                }

                const serverRecommendations = await recommendedWalkbookAPI.getAllRecommendations(firebaseToken, serverUser.campaign_id);
                if (isMounted.current) {
                  // safeSetState(setLoadingRecommendations, "Loading recommendations...");
                  safeSetState(setRecommendations, { type: 'REPLACE', newRecommendations: serverRecommendations }, ['newRecommendations']);
                  safeSetState(setLoadingRecommendations, "");
                }

                const serverVoterGroups = await voterGroupAPI.getAllVoterGroups(firebaseToken, serverUser.campaign_id);
                if (isMounted.current) {
                  // safeSetState(setLoadingVoterGroups, "Loading voter groups...");
                  safeSetState(setVoterGroups, { type: 'REPLACE', newVoterGroups: serverVoterGroups }, ['newVoterGroups']);
                  safeSetState(setLoadingVoterGroups, "");
                }

                const serverVoterTags = await voterTagsAPI.getAllTags(firebaseToken);
                if (isMounted.current) {
                  safeSetState(setVoterTags, { type: 'REPLACE', newVoterTags: serverVoterTags }, ['newVoterTags']);

                }

              }

              else { //if (serverUser.title === 'Staff' || serverUser.title === 'Volunteer') {
                const serverWalkbooks = await walkbookAPI.getAllUserWalkbooks(firebaseToken, serverUser.id, serverUser.campaign_id);
                if (isMounted.current) {
                  // safeSetState(setLoadingWalkbooks, "Loading walkbooks...");
                  safeSetState(setWalkbooks, { type: 'REPLACE', newWalkbooks: serverWalkbooks }, ['newWalkbooks']);
                  safeSetState(setLoadingWalkbooks, "");

                }

                const serverRoadSigns = await roadSignAPI.getAllRoadSigns(firebaseToken, serverUser.campaign_id);
                if (isMounted.current) {
                  // safeSetState(setLoadingRoadSigns, "Loading road signs...");
                  safeSetState(setRoadSigns, { type: 'REPLACE', newRoadSigns: serverRoadSigns }, ['newRoadSigns']);
                  safeSetState(setLoadingRoadSigns, "");
                }

                const serverVoterTags = await voterTagsAPI.getAllTags(firebaseToken);
                if (isMounted.current) {
                  safeSetState(setVoterTags, { type: 'REPLACE', newVoterTags: serverVoterTags }, ['newVoterTags']);

                }
              }

              if (isMounted.current) {
                safeSetState(setInvitation, null);
                safeSetState(setLoadingCampaign, "");
                safeSetState(setLoadingWalkbooks, "");
                safeSetState(setLoadingRoadSigns, "");
                safeSetState(setLoadingRecommendations, "");
                safeSetState(setLoadingVoterGroups, "");
              }

            } 
            
            // else {
            //   const serverInvitation = await invitationAPI.getInvitation(firebaseToken, serverUser.email);
            //   if (isMounted.current) {
            //     safeSetState(setInvitation, serverInvitation);
            //     // safeSetState(setLoadingCampaign, "");
            //     // safeSetState(setLoadingWalkbooks, "");
            //     // safeSetState(setLoadingRoadSigns, "");
            //     // safeSetState(setLoadingRecommendations, "");
            //     // safeSetState(setLoadingVoterGroups, "");

            //     // safeSetState(setLoading, "");
            //   }
            // }

            if (isMounted.current) {
              safeSetState(setLoading, "");
            }

          }

        } catch (error) {
          if (isMounted.current) {
            setErrorMessage('The server is currently unavailable. Please try again later.');
          }
        }
        // finally {
        //   if (isMounted.current) {
        //     safeSetState(setLoadingCampaign, "");
        //     safeSetState(setLoadingWalkbooks, "");
        //     safeSetState(setLoadingRoadSigns, "");
        //     safeSetState(setLoadingRecommendations, "");
        //     safeSetState(setLoadingVoterGroups, "");

        //     safeSetState(setLoading, "");

        //   }
        // }

        // if (Object.keys(campaignUser).length > 0) {

        //   try {
        //     const serverUser = await authAPI.getUser(firebaseToken);

        //     if (serverUser) {


        //       if (Object.keys(campaignUser).length > 0) {
        //         serverUser.campaign_id = campaignUser['campaign_id'] ? campaignUser['campaign_id'] : null;
        //         serverUser.title = campaignUser['title'] ? campaignUser['title'] : null;
        //         serverUser.date_joined = campaignUser['date_joined'] ? campaignUser['date_joined'] : null;
        //       }


        //       if (isMounted.current) {
        //         safeSetState(setLoading, "Reloading data...");
        //         safeSetState(setUser, serverUser);
        //       }

        //       if (serverUser.campaign_id) {

        //         const serverCampaign = await campaignAPI.getCampaign(firebaseToken, serverUser.campaign_id);
        //         if (isMounted.current) {
        //           safeSetState(setCampaign, serverCampaign);
        //           safeSetState(setLoadingCampaign, "");
        //         }
        //         if (serverUser.title === 'Manager' || serverUser.title === 'Director') {
        //           const serverWalkbooks = await walkbookAPI.getAllWalkbooks(firebaseToken, serverUser.campaign_id);
        //           if (isMounted.current) {
        //             safeSetState(setWalkbooks, { type: 'REPLACE', newWalkbooks: serverWalkbooks }, ['newWalkbooks']);
        //             safeSetState(setLoadingWalkbooks, "");

        //           }

        //           const serverRoadSigns = await roadSignAPI.getAllRoadSigns(firebaseToken, serverUser.campaign_id);
        //           if (isMounted.current) {
        //             safeSetState(setRoadSigns, { type: 'REPLACE', newRoadSigns: serverRoadSigns }, ['newRoadSigns']);
        //             safeSetState(setLoadingRoadSigns, "");
        //           }

        //           const serverRecommendations = await recommendedWalkbookAPI.getAllRecommendations(firebaseToken, serverUser.campaign_id);
        //           if (isMounted.current) {
        //             safeSetState(setRecommendations, { type: 'REPLACE', newRecommendations: serverRecommendations }, ['newRecommendations']);
        //             safeSetState(setLoadingRecommendations, "");
        //           }

        //           const serverVoterGroups = await voterGroupAPI.getAllVoterGroups(firebaseToken, serverUser.campaign_id);
        //           if (isMounted.current) {
        //             safeSetState(setVoterGroups, { type: 'REPLACE', newVoterGroups: serverVoterGroups }, ['newVoterGroups']);

        //             safeSetState(setLoadingVoterGroups, "");
        //           }

        //         }

        //         else { //if (serverUser.title === 'Staff' || serverUser.title === 'Volunteer') {
        //           const serverWalkbooks = await walkbookAPI.getAllUserWalkbooks(firebaseToken, serverUser.id, serverUser.campaign_id);
        //           if (isMounted.current) {
        //             safeSetState(setWalkbooks, { type: 'REPLACE', newWalkbooks: serverWalkbooks }, ['newWalkbooks']);
        //             safeSetState(setLoadingWalkbooks, "");

        //           }

        //           const serverRoadSigns = await roadSignAPI.getAllRoadSigns(firebaseToken, serverUser.campaign_id);
        //           if (isMounted.current) {
        //             safeSetState(setRoadSigns, { type: 'REPLACE', newRoadSigns: serverRoadSigns }, ['newRoadSigns']);

        //             safeSetState(setLoadingRoadSigns, "");
        //           }
        //         }

        //         if (isMounted.current) {
        //           safeSetState(setInvitation, {});
        //         }

        //       } else {

        //         const serverInvitation = await invitationAPI.getInvitation(firebaseToken, serverUser.email);
        //         if (isMounted.current) {
        //           safeSetState(setInvitation, serverInvitation);
        //           safeSetState(setLoadingCampaign, "");
        //           safeSetState(setLoadingWalkbooks, "");
        //           safeSetState(setLoadingRoadSigns, "");
        //           safeSetState(setLoadingRecommendations, "");
        //           safeSetState(setLoadingVoterGroups, "");

        //           safeSetState(setLoading, "");
        //         }
        //       }

        //     }

        //   } catch (error) {
        //     if (isMounted.current) {
        //       setErrorMessage('The server is currently unavailable. Please try again later.');
        //     }
        //   } finally {
        //     if (isMounted.current) {
        //       safeSetState(setLoadingCampaign, "");
        //       safeSetState(setLoadingWalkbooks, "");
        //       safeSetState(setLoadingRoadSigns, "");
        //       safeSetState(setLoadingRecommendations, "");
        //       safeSetState(setLoadingVoterGroups, "");

        //       safeSetState(setLoading, "");

        //     }
        //   }
        // }

      } else {
        if (isMounted.current) {
          console.log("currentUser not found");
          safeSetState(setAuthenticated, false);
          safeSetState(setVerified, false);
          // safeSetState(setLoadingCampaign, "");
          // safeSetState(setLoadingWalkbooks, "");
          // safeSetState(setLoadingRoadSigns, "");
          // safeSetState(setLoadingRecommendations, "");
          // safeSetState(setLoadingVoterGroups, "");
          // safeSetState(setLoading, "");
        }
      }
    }


    if (currentUser) {
      onLoad();
    }

  // }, [currentUser]);
  }, [currentUser, campaignUser, setAuthenticated, setVerified, setUser, setInvitation, setCampaign, setWalkbooks, setRoadSigns, setRecommendations, setVoterGroups, setVoterTags, setLoading, setLoadingCampaign, setLoadingWalkbooks, setLoadingRoadSigns, setLoadingRecommendations, setLoadingVoterGroups]);

  return (
    <div>
      {errorMessage && <div className="error-message">{errorMessage}</div>}
    </div>
  );
};

export default DataLoader;
