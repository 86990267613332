import React, { useRef, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { auth } from '../../contexts/Firebase';
import { sendPasswordResetEmail } from "firebase/auth";



// react-bootstrap components
import {
  Alert,
  Button,
  Card,
  Form,
  FloatingLabel,
  Container,
  Col,
  Row,
  Modal
} from "react-bootstrap";


import authAPI from '../../apis/auth/auth';


import LoadingSpinner from "../../components/Spinner/LoadingSpinner";
import LocalLoadingSpinner from "../../components/Spinner/LocalLoadingSpinner";
import { useUserContext } from "../../contexts/UserContext";


const emailValidation = (value) =>
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
    value
  );


const minLength = (value, length) => value.length >= length;

function LoginPage() {

  const {
    currentUser,
    setUser,
    authenticating,
    loading,
    setLoading,
    setRequiresRegistration,
    authenticated,

  } = useUserContext();

  const history = useHistory();

  const [email, setEmail] = useState('');
  const [emailState, setEmailState] = useState(false);

  const [password, setPassword] = useState('');
  const [passwordState, setPasswordState] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [resetEmail, setResetEmail] = useState('');


  const [error, setError] = useState('');

  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);


  const callSubmit = async (e) => {
    e.preventDefault();
    if (isMounted.current) {
      setError('');
      setLoading('Authenticating...');
    }

    try {
      const firebaseUser = await authAPI.signInWithEmail(email, password); // Assumes signInWithEmail does the Firebase auth signInWithEmailAndPassword call

      // After successful sign-in, ensure currentUser is updated
      if (firebaseUser && firebaseUser.getIdToken) {
        const firebaseToken = await firebaseUser.getIdToken(); // Use firebaseUser directly if possible
        const fetchedUserResult = await authAPI.getUser(firebaseToken);
        if (fetchedUserResult.status === "registration_required") {
          if (isMounted.current) {
            setRequiresRegistration(true);
            setUser(fetchedUserResult);
            setLoading("");
          }

          history.push('/auth/register');

        } else if (fetchedUserResult.detail === "Email not found or not invited") {
          if (isMounted.current) {
            setLoading("");
            setError("You have not been invited to register yet.");
          }

        } else if (fetchedUserResult) {

          if (isMounted.current) {
            setUser(fetchedUserResult);
            setLoading("");
          }

          history.push('/');

        } else {

          if (isMounted.current) {
            setLoading("");
            setError("Unhandled user state, please try again.");
          }

        }
      } else {
        // Handle the case where firebaseUser is not as expected
        console.error("User sign-in was not successful.");
        if (isMounted.current) {
          setError("User sign-in was not successful.");
          setLoading("");
        }
      }
    } catch (err) {
      console.log("err: ", err);
      if (err instanceof Error) {
        if (isMounted.current) {
          if (err.message === 'Firebase: Error (auth/wrong-password).' || err.message === 'Firebase: Error (auth/user-not-found).') {
            setError('Incorrect email or password.');

          } else if (err.message === 'LOGIN_BAD_CREDENTIALS') {
            setError('Incorrect email or password.');

          } else if (err.message === 'LOGIN_NO_USER') {
            setError('No account found with that email.');

          } else {
            setError('Server Error: Try again later. ' + err);

          }
          setLoading("");
        }
      } else {
        if (isMounted.current) {

          setError('Server Error: Try again later. ' + err);
          setLoading("");
        }
      }
    }
    // finally {
    //   if (isMounted.current) {
    //     setLoading("");
    //   }
    // }
  };

  const handlePasswordReset = async (email) => {
    if (!email) {
      setError('Please enter your email address.');
      return;
    }
    try {
      await sendPasswordResetEmail(auth, email);
      // alert('Password reset email sent! Please check your inbox.');
      setShowModal(false); // Close the modal after sending the email
      setResetEmail(''); // Reset the email field in the modal
    } catch (error) {
      console.error('Error sending password reset email:', error);
      setError('Failed to send password reset email. Please check your email and try again.');
    }
  };
  
  

  // const [cardClasses, setCardClasses] = useState("");

  
  const [cardClasses, setCardClasses] = useState("card-hidden");

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isMounted.current) {
        setCardClasses("");
      }
    }, 100);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <div className="content d-flex align-items-center p-0">
        <Container>

          {((loading !== null) && (loading !== "")) ? (
            <LoadingSpinner />
          ) : (
            (!authenticated) ? (
              <Col className="mx-auto" lg="4" md="8">
                <Form action="" className="form" method="">
                  <Card className={"card-login " + cardClasses} style={{ paddingBottom: '10px' }}>
                    <Card.Header>
                      <h3 className="header text-center">Login</h3>
                    </Card.Header>
                    <Card.Body className="align-items-center">
                      <Row className="justify-content-center">
                        <Form.Group
                          className={emailState ? "has-success" : "has-error"}
                          controlId="formBasicEmail"
                        >
                          <FloatingLabel
                            controlId="floatingEmailInput"
                            label="Email"
                            className="mb-3"
                          >
                            <Form.Control
                              name="email"
                              type="email"
                              value={email}
                              onChange={(e) => {
                                setEmail(e.target.value);
                                if (emailValidation(e.target.value)) {
                                  setEmailState(true);
                                } else {
                                  setEmailState(false);
                                }
                              }}
                              autoComplete="email"
                            />
                          </FloatingLabel>
                        </Form.Group>
                        <Form.Group
                          className={passwordState ? "has-success" : "has-error"}
                          controlId="formBasicPassword"
                        >
                          <FloatingLabel
                            controlId="floatingPasswordInput"
                            label="Password"
                            className="mb-3"
                          >
                            <Form.Control
                              name="password"
                              type="password"
                              value={password}
                              onChange={(e) => {
                                setPassword(e.target.value);
                                if (minLength(e.target.value, 1)) {
                                  setPasswordState(true);
                                } else {
                                  setPasswordState(false);
                                }
                              }}
                            />
                          </FloatingLabel>
                          <div className="text-center mt-2">
                            <Button variant="link" onClick={() => setShowModal(true)} style={{ textDecoration: 'underline', color: '#6BA0F2' }}>
                              Forgot password?
                            </Button>
                          </div>
                        </Form.Group>
                      </Row>
                      <Row className="justify-content-center" style={{ marginBottom: '15px' }}>
                        <div
                          className="text-center text-danger"
                          style={error !== '' ? { display: "block", maxWidth: "300px" } : { display: "none" }}
                        >
                          {error}
                        </div>
                      </Row>
                      <Row className="align-items-center justify-content-center">
                        <Button
                          className="btn-wd login-btn"
                          onClick={(e) => {
                            if (emailState && passwordState) {
                              callSubmit(e);
                            }
                          }}
                          disabled={loading !== ""}
                        >
                          {loading !== "" ? "Signing in..." : "Sign in"}
                        </Button>
                      </Row>
                    </Card.Body>
                  </Card>
                </Form>
              </Col>
            ) : (
              <div className="content d-flex align-items-center p-0">
                <Container>
                  <Col className="mx-auto" lg="6" md="8">
                    <Card className="card-login">
                      <Card.Body>
                        {" "}<br />
                        <h3 className="header text-center">{"Authenticated"}</h3>
                        {" "}<br />
                      </Card.Body>
                    </Card>
                  </Col>
                </Container>
              </div>
            )
            // )
          )}
          <Modal show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Reset Password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Enter the email for the account you would like to reset the password for:</p>
              <Form>
                <Form.Group>
                  <FloatingLabel controlId="floatingInput" label="Email address" className="mb-3">
                    <Form.Control
                      type="email"
                      placeholder="name@example.com"
                      value={resetEmail}
                      onChange={(e) => setResetEmail(e.target.value)}
                      autoComplete="email"
                    />
                  </FloatingLabel>
                </Form.Group>
                <div className="d-flex justify-content-end">
                  {/* <Button variant="secondary" onClick={() => setShowModal(false)} style={{ marginRight: '10px' }}>
                    Close
                  </Button> */}
                  <Button variant="primary" onClick={() => handlePasswordReset(resetEmail)}>
                    Send Reset Link
                  </Button>
                </div>
              </Form>
            </Modal.Body>
          </Modal>
        </Container>
      </div>
    </>
  );
}

export default LoginPage;




{/*
<>
  <div className="content d-flex align-items-center p-0">
    <Container>
      <Col className="mx-auto" lg="4" md="8">
        <Form onSubmit={callSubmit} action="" className="form" method="">
          <Card className={"card-login " + cardClasses} style={{ paddingBottom: '10px'}}>
            <Card.Header>
              <h3 className="header text-center">Login</h3>
            </Card.Header>
            <Card.Body className="align-items-center" style={{ borderBottom: 'solid rgba(185, 185, 185, 0.521) 2px' }}>
              <Row className="justify-content-center">
                <Form.Group
                  className={emailState ? "has-success" : "has-error"}
                  controlId="formBasicEmail"
                >
                  {emailState ? (
                    <FloatingLabel
                      controlId="floatingEmailInput"
                      label="Email"
                      className="mb-3"
                    >
                      <Form.Control
                        name="email"
                        type="text"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                          if (emailValidation(e.target.value)) {
                            setEmailState(true);
                          } else {
                            setEmailState(false);
                          }
                        }}
                      ></Form.Control>
                    </FloatingLabel>
                  ) : (
                    <FloatingLabel
                      controlId="floatingEmailInput"
                      label="This field is required and must be a valid email address."
                      className="error"
                    >
                      <Form.Control
                        name="email"
                        type="text"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                          if (emailValidation(e.target.value)) {
                            setEmailState(true);
                          } else {
                            setEmailState(false);
                          }
                        }}
                      ></Form.Control>
                    </FloatingLabel>
                  )}
                </Form.Group>
                <Form.Group
                  className={passwordState ? "has-success" : "has-error"}
                  controlId="formBasicPassword"
                >
                  {passwordState ? (
                    <FloatingLabel
                      controlId="floatingPasswordInput"
                      label="Password"
                      className="mb-3"
                    >
                      <Form.Control
                        name="password"
                        type="password"
                        value={password}
                        onChange={(e) => {
                          setPassword(e.target.value);
                          if (minLength(e.target.value, 1)) {
                            setPasswordState(true);
                          } else {
                            setPasswordState(false);
                          }
                        }}
                      />
                    </FloatingLabel>
                  ) : (
                    <FloatingLabel
                      controlId="floatingPasswordInput"
                      label="This field is required."
                      className="error"
                    >
                      <Form.Control
                        name="password"
                        type="password"
                        value={password}
                        onChange={(e) => {
                          setPassword(e.target.value);
                          if (minLength(e.target.value, 1)) {
                            setPasswordState(true);
                          } else {
                            setPasswordState(false);
                          }
                        }}
                      ></Form.Control>
                    </FloatingLabel>
                  )}
                </Form.Group>
              </Row>
              <Row className="justify-content-center">
                <Alert color='danger' style={ error!=='' ? {display:"block", maxWidth:"300px"} : {display:"none"}}>
                  {error}
                </Alert>
              </Row>
              <Row className="align-items-center justify-content-center">
                <Button className="btn-wd login-btn" onClick={callSubmit} >
                  Sign in
                </Button>
              </Row>
            </Card.Body>
            <Card.Footer className="justify-content-center" style={{ borderTop: 'solid rgba(185, 185, 185, 0.521) 2px'}}>
              <Row className="justify-content-center">
                <Button
                  className="btn-wd login-with-google-btn"
                  style={{ boxShadow: '-1px -1px 3px rgba(0, 0, 0, 0.24), 1px 1px 3px rgba(0, 0, 0, 0.24)' }}
                  variant="none"
                  onClick={googleLogin}
                >
                  Sign in with Google
                </Button>
              </Row>
            </Card.Footer>
          </Card>
        </Form>
      </Col>
    </Container>
  </div>
</>
*/}
