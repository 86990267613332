class VoterGroup {

  constructor() {
    this.baseURL = process.env.REACT_APP_API_BASE_URL;
  }


  createVoterGroup = async (token, name, type, creationMethod, filterParams, campaign_id, user_id) => {
    // console.log(name);
    // console.log(creationMethod);
    // console.log(filterParams);

    // const token = localStorage.getItem('firebase_token');

    // Create data JSON
    const formData = {
      "name": name,
      "type": type, // "Static" or "Dynamic
      "creation_method": creationMethod,
      "status": "Pending",
      // "geo_data": geoData[0],
      "filter_params": filterParams,
      "campaign_id": campaign_id,
      "user_id": user_id,
    };

    // console.log(formData);
    
    if (token) {
      const request = new Request(`${this.baseURL}/voter-group/`, {
        method: 'POST',
        headers: {
          'accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'X-client_type': 'web'
        },
        body: JSON.stringify(formData),
        credentials: 'include',
      });

      // Fetch request
      const response = await fetch(request);
      // 500 error handling
      if (response.status === 500) {
        throw new Error('Internal server error');
      }
      // 400 error handling
      const data = await response.json();
      if (response.status >= 400 && response.status < 500) {
        if (data.detail) {
          throw data.detail;
        }
        throw data;
      }
      return data;
    } else {
      console.error("No token found");
    }
  };


  getVoterGroup = async (token, id) => {
    // const token = localStorage.getItem('firebase_token');

    if (token) {
      if (id) {
        // Create request
        const request = new Request(`${this.baseURL}/voter-group/?group_id=`+id, {
          method: 'GET',
          credentials: 'include',
          headers: {'Authorization': `Bearer ${token}`}
        });
        // Fetch request
        const response = await fetch(request);
        const data = await response.json();
        //console.log(data);
        return data;
      }
      else {
        throw new Error('ID was not provided');
      }
    } else {
      console.error("No token found");
    }
  };


  getVoterGroupVoters = async (token, id, skip, limit) => {
    // console.log("In api call");
    // console.log("id: ", id);
    // console.log("skip: ", skip);
    // console.log("limit: ", limit);
    // const token = localStorage.getItem('firebase_token');

    if (token) {
      // if (id && skip && limit) {
      if (id !== undefined && id !== null && skip !== undefined && skip !== null && limit !== undefined && limit !== null) {
        // Create request
        const request = new Request(`${this.baseURL}/voter-group/voters/?group_id=`+id+'&skip='+skip+'&limit='+limit, {
          method: 'GET',
          credentials: 'include',
          headers: {'Authorization': `Bearer ${token}`}
        });
        // Fetch request
        const response = await fetch(request);
        const data = await response.json();
        //console.log(data);
        return data;
      }
      else {
        throw new Error('One or more parameters were not provided');
        // throw new Error('ID was not provided');
      }
    } else {
      console.error("No token found");
    }
  };


  getAllVoterGroups = async (token, campaign_id) => {
    // const token = localStorage.getItem('firebase_token');
    if (token) {
      if (campaign_id) {
        // Create request
        const request = new Request(`${this.baseURL}/voter-group/all/?campaign_id=`+campaign_id, {
          method: 'GET',
          credentials: 'include',
          headers: {'Authorization': `Bearer ${token}`}
        });
        // Fetch request
        const response = await fetch(request);
        const data = await response.json();
        //console.log(data);
        return data;
      }
      else {
        throw new Error('ID or Name was not provided');
      }
    } else {
      console.error("No token found");
    }
  };


  updateVoterGroup = async (token, id, name, description, status, campaign_id, user_id) => {
    // const localUser = JSON.parse(localStorage.getItem('user')) ?? {};
    // const token = localUser['oauth_account']['access_token'];
    // const token = localStorage.getItem('firebase_token');

    const formData = {
      "id": id,
      "name": name,
      "description": description,
      "status": status,
      "campaign_id": campaign_id,
      "user_id": user_id,
    };
    
    if (token) {
      if (id) {
        // Create request
        const request = new Request(`${this.baseURL}/voter-group/`, {
          method: 'PUT',
          headers: {
            'accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'X-client_type': 'web'
          },
          body: JSON.stringify(formData),
          credentials: 'include',
        });
        // Fetch request
        const response = await fetch(request);
        const data = await response.json();
        // console.log(data);
        return data;

      } else {
        throw new Error('ID or Name was not provided');
      }
    } else {
      console.error("No token found");
    }
  };


  deleteVoterGroup = async (token, name) => {
    // const token = localStorage.getItem('firebase_token');

    if (token) {
      if (name) {
        console.log("Here 3");
        // Create request
        const request = new Request(`${this.baseURL}/voter-group/?name=`+name, {
          method: 'DELETE',
          credentials: 'include',
          //headers: {'Authorization': `Bearer ${token}`}
        });
        // Fetch request
        const response = await fetch(request);
        console.log("Here 4");
        console.log(response.status);
        
        
        if (response.status === 204) {
          return true;
        } else {
          const data = await response.json();
          if (data.detail) {
            throw data.detail;
          }
          throw data;
        }
      } else {
        throw new Error('VoterGroup Name was not provided');
      }
    } else {
      console.error("No token found");
    }
  };

}

export default new VoterGroup();