import React from "react";
import { Switch, Route } from "react-router-dom";

import AuthNavbar from "../components/Navbars/AuthNavbar.js";

import LoginPage from "../views/Auth/LoginPage.js";
import RegisterPage from "../views/Auth/RegisterPage.js";
import VerifyPage from "../views/Auth/VerifyPage.js";


function Auth() {

  return (
    <>
      <div className="wrapper wrapper-full-page">
        <div
          className="full-page section-image"
          data-color="black"
        >
          <AuthNavbar />


          <Switch>
            <Route path={"/auth/register"}>
              <RegisterPage />
            </Route>
            <Route path={"/auth/verify"}>
              <VerifyPage />
            </Route>
            <Route path={"/auth/login"}>
              <LoginPage />
            </Route>
          </Switch>
          {/* )} */}


        </div>
      </div>
    </>
  );
}

export default Auth;