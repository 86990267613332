import { useEffect, useContext, createContext, useReducer, useState } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import CryptoJS from 'crypto-js';




const secretKey = process.env.REACT_APP_SECRET_KEY; 

const encryptData = (data) => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
};

const decryptData = (data) => {
  const bytes = CryptoJS.AES.decrypt(data, secretKey);
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};


// let campaignUserReducer = (campaignUser, newCampaignUser) => {
//   if (newCampaignUser === null) {
//     localStorage?.removeItem('campaignUser');
//     return initialCampaignUserState;
//   }
//   return { ...campaignUser, ...newCampaignUser };
// };
// const initialCampaignUserState = {};
// const localCampaignUserState = JSON.parse(localStorage.getItem('campaignUser'));


const initialCampaignUserState = {};

// Adjust localStorage access for campaignUser to include encryption/decryption
const localCampaignUserData = localStorage.getItem('campaignUser');
const localCampaignUserState = localCampaignUserData ? decryptData(localCampaignUserData) : initialCampaignUserState;

let campaignUserReducer = (campaignUser, newCampaignUser) => {
  if (newCampaignUser === null) {
    localStorage.removeItem('campaignUser');
    return initialCampaignUserState;
  }
  return { ...campaignUser, ...newCampaignUser };
};


const initialInvitationState = {};
const localInvitationData = localStorage.getItem('invitation');
const localInvitationState = localInvitationData ? decryptData(localInvitationData) : initialInvitationState;

let invitationReducer = (invitation, newInvitation) => {
  if (newInvitation === null) {
    localStorage.removeItem('invitation');
    return initialInvitationState;
  }
  return { ...invitation, ...newInvitation };
};



// let invitationReducer = (invitation, newInvitation) => {
//   return { ...invitation, ...newInvitation };
// };
// const initialInvitationState = {};




let userReducer = (user, newUser) => {
  return { ...user, ...newUser };
};
const initialUserState = {};


let campaignReducer = (campaign, newCampaign) => {
  return { ...campaign, ...newCampaign };
};
const initialCampaignState = {};


let walkbooksReducer = (walkbooks, action) => {
  switch (action.type) {
    case 'APPEND':
      return [...walkbooks, ...action.newWalkbooks];

    case 'REMOVE':
      return walkbooks.filter(walkbook => walkbook.id !== action.id);

    case 'UPDATE':
      return walkbooks.map(walkbook =>
        walkbook.id === action.updatedWalkbook.id
          ? action.updatedWalkbook
          : walkbook
      );

    case 'REPLACE':
      return [...action.newWalkbooks];

    default:
      return walkbooks;
  }
};
const initialWalkbooksState = [];


let roadSignsReducer = (roadSigns, action) => {
  switch (action.type) {
    case 'APPEND':
      return [...roadSigns, ...action.newRoadSigns];

    case 'REMOVE':
      return roadSigns.filter(roadSign => roadSign.id !== action.id);

    case 'UPDATE':
      return roadSigns.map(roadSign =>
        roadSign.id === action.updatedRoadSign.id
          ? action.updatedRoadSign
          : roadSign
      );

    case 'REPLACE':
      return [...action.newRoadSigns];

    default:
      return roadSigns;
  }
};
const initialRoadSignsState = [];


let recommendationsReducer = (recommendations, action) => {
  switch (action.type) {
    case 'APPEND':
      return [...recommendations, ...action.newRecommendations];

    case 'REMOVE':
      return recommendations.filter(recommendation => recommendation.id !== action.id);

    case 'UPDATE':
      return recommendations.map(recommendation =>
        recommendation.id === action.updatedRecommendation.id
          ? action.updatedRecommendation
          : recommendation
      );

    case 'REPLACE':
      return [...action.newRecommendations];

    default:
      return recommendations;
  }
};
const initialRecommendationsState = [];


let voterGroupsReducer = (voterGroups, action) => {
  switch (action.type) {
    case 'APPEND':
      return [...voterGroups, ...action.newVoterGroups];

    case 'REMOVE':
      return voterGroups.filter(voterGroup => voterGroup.id !== action.id);

    case 'UPDATE':
      return voterGroups.map(voterGroup =>
        voterGroup.id === action.updatedVoterGroup.id
          ? action.updatedVoterGroup
          : voterGroup
      );

    case 'REPLACE':
      return [...action.newVoterGroups];

    default:
      return voterGroups;
  }
};
const initialVoterGroupsState = [];


let voterTagsReducer = (voterTags, action) => {
  switch (action.type) {
    case 'APPEND':
      return [...voterTags, ...action.newVoterTags];

    case 'REMOVE':
      return voterTags.filter(voterTag => voterTag.id !== action.id);

    case 'UPDATE':
      return voterTags.map(voterTag =>
        voterTag.id === action.updatedVoterTag.id
          ? action.updatedVoterTag
          : voterTag
      );

    case 'REPLACE':
      return [...action.newVoterTags];

    default:
      return voterTags;
  }
};
const initialVoterTagsState = [];


const UserContext = createContext(null);


export function UserProvider(props) {
  const [authenticating, setAuthenticating] = useState(true);
  const [authenticated, setAuthenticated] = useState(false);
  const [requiresRegistration, setRequiresRegistration] = useState(false);
  const [verified, setVerified] = useState(false);
  const [loading, setLoading] = useState("");
  const [loadingCampaign, setLoadingCampaign] = useState("");
  const [loadingWalkbooks, setLoadingWalkbooks] = useState("");
  const [loadingRoadSigns, setLoadingRoadSigns] = useState("");
  const [loadingVoterGroups, setLoadingVoterGroups] = useState("");
  const [loadingRecommendations, setLoadingRecommendations] = useState("");



  // const [campaignUser, setCampaignUser] = useReducer(campaignUserReducer, localCampaignUserState || initialCampaignUserState);
  // useEffect(() => {
  //   const encryptedData = encryptData(campaignUser);
  //   localStorage.setItem('campaignUser', encryptedData);
  // }, [campaignUser]);


  // const [invitation, setInvitation] = useReducer(invitationReducer, localInvitationState || initialInvitationState);
  // useEffect(() => {
  //   const encryptedData = encryptData(invitation);
  //   localStorage.setItem('invitation', encryptedData);
  // }, [invitation]);

  const [campaignUser, setCampaignUser] = useReducer(campaignUserReducer, localCampaignUserState || initialCampaignUserState);
  useEffect(() => {
    if (Object.keys(campaignUser).length > 0) {
      const encryptedData = encryptData(campaignUser);
      localStorage.setItem('campaignUser', encryptedData);
    } else {
      localStorage.removeItem('campaignUser');
    }
  }, [campaignUser]);

  const [invitation, setInvitation] = useReducer(invitationReducer, localInvitationState || initialInvitationState);
  useEffect(() => {
    if (Object.keys(invitation).length > 0) {
      const encryptedData = encryptData(invitation);
      localStorage.setItem('invitation', encryptedData);
    } else {
      localStorage.removeItem('invitation');
    }
  }, [invitation]);



  const [currentUser, setCurrentUser] = useState(null); // [currentUser, setCurrentUser] = useState(null);
  const [user, setUser] = useReducer(userReducer, initialUserState);
  // const [invitation, setInvitation] = useReducer(invitationReducer, initialInvitationState);
  const [campaign, setCampaign] = useReducer(campaignReducer, initialCampaignState);
  const [walkbooks, setWalkbooks] = useReducer(walkbooksReducer, initialWalkbooksState);
  const [roadSigns, setRoadSigns] = useReducer(roadSignsReducer, initialRoadSignsState);
  // const [recommendedWalkbooks, setRecommendedWalkbooks] = useReducer(recommendedWalkbooksReducer, initialRecommendedWalkbooksState);
  const [recommendations, setRecommendations] = useReducer(recommendationsReducer, initialRecommendationsState);
  const [voterGroups, setVoterGroups] = useReducer(voterGroupsReducer, initialVoterGroupsState);
  const [voterTags, setVoterTags] = useReducer(voterTagsReducer, initialVoterTagsState);


  useEffect(() => {
    const auth = getAuth();
    setAuthenticating(true);
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      // console.log('UserProvider onAuthStateChanged user:', user);
      setCurrentUser(user);
      setAuthenticating(false);
      if (user) {
        // User is logged in
        setAuthenticated(true);
        setVerified(user.emailVerified);
      } else {
        // No user is logged in
        setAuthenticated(false);
      }
    });

    // Clean up the observer when the component unmounts
    return unsubscribe;
  }, []);

  return (
    <UserContext.Provider value={{
      campaignUser,
      setCampaignUser,
      currentUser,
      setCurrentUser,
      user,
      setUser,
      loading,
      setLoading,
      authenticating,
      setAuthenticating,
      authenticated,
      setAuthenticated,
      requiresRegistration,
      setRequiresRegistration,
      verified,
      setVerified,
      invitation,
      setInvitation,
      campaign,
      setCampaign,
      walkbooks,
      setWalkbooks,
      roadSigns,
      setRoadSigns,
      // recommendedWalkbooks,
      // setRecommendedWalkbooks,
      recommendations,
      setRecommendations,
      voterGroups,
      setVoterGroups,
      voterTags,
      setVoterTags,
      loadingCampaign,
      setLoadingCampaign,
      loadingWalkbooks,
      setLoadingWalkbooks,
      loadingRoadSigns,
      setLoadingRoadSigns,
      loadingVoterGroups,
      setLoadingVoterGroups,
      loadingRecommendations,
      setLoadingRecommendations
    }}>
      {props.children}
    </UserContext.Provider>
  );
}

export function useUserContext() {
  return useContext(UserContext);
}